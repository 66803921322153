import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const voucherRequest = ({ code, items, email }) =>
  fetch(`${publicRuntimeConfig.api}/voucher`, {
    method: 'POST',
    body: JSON.stringify({
      code: code,
      items: items,
      email: email
    }),
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(async res => ({ status: res.status}))
    .then(res => {
      if (res.status !== 200) {
        const error = res.message || 'Invalid Code'
        throw new Error(error)
      }
    })

export default voucherRequest
