import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const url = publicRuntimeConfig.publicStore + '/api/2022-01/graphql.json'

const client =
  ([query]) =>
  responseHandler =>
  variables =>
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token':
          publicRuntimeConfig.storefrontAccessToken
      },
      body: JSON.stringify({
        query,
        variables
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.errors) {
          throw new Error(res.errors[0].message)
        }
        return res.data
      })
      .then(data => responseHandler(data, variables))

export default client
