import gql from './client'

// example input
const variables = {
  ids: ['gid://shopify/ProductVariant/1234']
}

// https://shopify.dev/api/storefront/reference/products/productvariant
// https://shopify.dev/api/storefront/reference/products/product
const query = gql`
  query ($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        availableForSale
        compareAtPriceV2 {
          amount
        }
        priceV2 {
          amount
        }
        quantityAvailable
        sku
        title
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
        }
        product {
          handle
          title
          images(first: 1) {
            edges {
              node {
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async res => {
  const nodes = res.nodes.filter(o => o)
  if (!nodes.length) {
    throw new Error('no nodes in response')
  }
  return nodes
}

export default query(responseHandler)
