const SizeGuideBucket = () => {
  return (
    <>
      <h3>BUCKET HAT SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Age</th>
              <th>Circumference (cm)</th>
              <th>Circumference (in)</th>
              <th>Cap Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2">Child (4-6 yrs)</td>
              <td>52</td>
              <td>20 1/2</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>53</td>
              <td>20 7/8</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="2">Youth (6-12 yrs)</td>
              <td>53.9</td>
              <td>21 1/4</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>54.9</td>
              <td>21 5/8</td>
              <td>XS or S/M</td>
            </tr>
            <tr>
              <td rowSpan="9">Adult</td>
              <td>55.8</td>
              <td>22</td>
              <td>XS or S/M</td>
            </tr>
            <tr>
              <td>56.8</td>
              <td>22 3/8</td>
              <td>XS or S/M</td>
            </tr>
            <tr>
              <td>57.7</td>
              <td>22 3/4</td>
              <td>S</td>
            </tr>
            <tr>
              <td>58.7</td>
              <td>23 1/8</td>
              <td>M or M/L</td>
            </tr>
            <tr>
              <td>59.6</td>
              <td>23 1/2</td>
              <td>L</td>
            </tr>
            <tr>
              <td>60.6</td>
              <td>23 7/8</td>
              <td>XL or L/XL</td>
            </tr>
            <tr>
              <td>61.5</td>
              <td>24 1/4</td>
              <td>XXL</td>
            </tr>
            <tr>
              <td>62.5</td>
              <td>24 5/8</td>
              <td>XXL</td>
            </tr>
            <tr>
              <td>63.5</td>
              <td>25</td>
              <td>XXL</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideBucket.type = 'bucket'

export default SizeGuideBucket
