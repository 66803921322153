import gql from './client'

// https://shopify.dev/api/storefront/reference/customers/customer
// https://shopify.dev/api/storefront/reference/common-objects/mailingaddress
const query = gql`
  query ($accessToken: String!, $numAddresses: Int!, $numOrders: Int!) {
    customer(customerAccessToken: $accessToken) {
      id
      firstName
      lastName
      email
      phone
      acceptsMarketing
      createdAt
      updatedAt
      defaultAddress {
        id
      }
      addresses(first: $numAddresses) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            address1
            address2
            city
            zip
            company
            country
            firstName
            lastName
            phone
          }
        }
      }
      orders(first: $numOrders, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            name
            financialStatus
            fulfillmentStatus
            statusUrl
            processedAt
            orderNumber
            originalTotalPrice {
              amount
              currencyCode
            }
            currentTotalPrice {
              amount
              currencyCode
            }
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            discountApplications(first: 100) {
              edges {
                node {
                  allocationMethod
                  targetSelection
                  targetType
                  value {
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
            lineItems(first: 100) {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                node {
                  title
                  quantity
                  variant {
                    id
                    title
                    sku
                    selectedOptions {
                      name
                      value
                    }
                    image {
                      url
                    }
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getNodesAndCursor = ({ pageInfo, edges }) => {
  const cursor = pageInfo.hasNextPage && edges[edges.length - 1].cursor
  const nodes = edges.map(add => add.node)
  return [nodes, cursor]
}

const responseHandler = async res => {
  let { addresses: a, orders: o, ...customer } = res.customer

  let [addresses, addressCursor] = getNodesAndCursor(a)
  let [orders, ordersCursor] = getNodesAndCursor(o)

  orders.map(order => {
    ;[order.lineItems] = getNodesAndCursor(order.lineItems)
  })

  return {
    ...customer,
    addresses,
    addressCursor,
    orders,
    ordersCursor,
    fetched: Date.now()
  }
}

export default query(responseHandler)
