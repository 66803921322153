import gql from './client'

// example input
const variables = {
  input: {
    // acceptsMarketing: true,
    email: 'user@example.com',
    firstName: 'optional',
    lastName: 'optional',
    password: 'HiZqFuDvDdQ7',
    phone: 'optionalString'
  }
}

// https://shopify.dev/api/storefront/reference/customers/customeraddresscreate
const query = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerAddressCreate }) => {
  const { customerUserErrors } = customerAddressCreate
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
}

export default query(responseHandler)
