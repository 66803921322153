export default function resizeShopifyImage(src, { w, h, q = 80 }) {
  if (!src) return null

  const url = new URL(src)
  if (w) url.searchParams.set('width', w)
  if (h) url.searchParams.set('height', h)
  if (q) url.searchParams.set('quality', q)

  return url.toString()
}
