import cx from 'clsx'
import Link from '@/components/common/link'
import styles from '../category-accordion.module.scss'
import Icon from '@/components/common/icon'
import { useRef, useState, useEffect } from 'react'

export const CategoryGroup = ({ group, groupKey, active, onGroupToggle }) => {
  const groupEl = useRef()
  const [height, setHeight] = useState(0)

  const preventLink = e => {
    e.preventDefault()
  }

  const handleClick = () => {
    const groupHeight =
      groupEl.current && !active ? groupEl.current.scrollHeight : 0
    onGroupToggle(groupKey, groupHeight)
  }

  // Save parent height when active changes
  useEffect(() => {
    if (groupEl.current) {
      setHeight(groupEl.current.scrollHeight)
    }
  }, [active])

  return (
    <li
      className={cx(styles.item, styles.group, {
        [styles.active]: active,
        [styles.hasAccordion]: group.items?.length > 0
      })}
      onClick={handleClick}
    >
      {group.title ? (
        <>
          <Link
            href={group.url}
            onClick={group.items?.length > 0 ? preventLink : undefined}
          >
            <span className={styles.groupTitle}>{group.title}</span>
            {group.items?.length > 0 && (
              <span className="ml-2">
                {active ? <Icon icon="AngleUp" /> : <Icon icon="AngleDown" />}
              </span>
            )}
          </Link>
          <div
            ref={groupEl}
            className={styles.inner}
            style={active ? { height: height } : { height: '0px' }}
          >
            {group?.items?.length && (
              <ul className={styles.list}>
                {group.items.map((child, index) => (
                  <li className={cx(styles.item, styles.child)} key={index}>
                    <Link href={child.url + "?shown=24&sort=featured"}>
                      <span>{child.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      ) : (
        <ul className={styles.list}>
          {group.items.map((child, index) => (
            <li className={cx(styles.item, styles.child)} key={index}>
              <Link href={child.url + "?shown=24&sort=featured"}>
                <span>{child.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}
