import useBasket from '@/hooks/useBasket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import useNav from '@/hooks/useNav'

export const ButtonBasket = () => {
  const [toggleBasket] = useNav('basket')
  const { count } = useBasket()

  return (
    <button
      onClick={toggleBasket}
      className="btn-clear d-flex align-items-center color-white lh-normal"
    >
      <FontAwesomeIcon icon={faShoppingCart} size="lg" />
      <span className="ml-1 text-uppercase p-lg">{count}</span>
    </button>
  )
}
