import Icon from '@/components/common/icon'

export default function Submitted() {
  return (
    <div class="text-center py-4">
      <p className="h4 text-uppercase d-block">
        We are processing your order...
      </p>
      <p className="d-block mb-5 px-3">
        Please do not refresh your browser until this has been completed
      </p>
      <span className="d-block">
        <Icon icon="Spinner" pulse={true} size="2x" />
      </span>
    </div>
  )
}
