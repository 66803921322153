/**
 * Stores an item on the window object.
 *
 * Used for remembering state between pages.
 *
 * Current usage: remember('color', colorName) before navigating to a
 * product will select that color option
 */
if (typeof window !== 'undefined') {
  window._mem = {}
}

export const remember = (key, value) => {
  window._mem[key] = value
}

export const rememberCurried = (key, value) => () => remember(key, value)

export const recall = key => {
  const value = window._mem[key]
  delete window._mem[key]
  return value
}
