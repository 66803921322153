import cx from 'clsx'
import Icon from '@/components/common/icon'
import { useState, useEffect, useRef } from 'react'
import styles from './off-canvas.module.scss'
import { useRouter } from 'next/router'

export const OffCanvas = ({
  open,
  setOpen,
  children,
  className,
  right,
  close = true
}) => {
  const [show, setShow] = useState(open)
  const timeout = useRef()
  const router = useRouter()

  const openAnimate = () => {
    setShow(true)
    document.documentElement.classList.add('no-scroll')
    timeout.current && clearTimeout(timeout.current)
  }

  const closeAnimate = () => {
    document.documentElement.classList.remove('no-scroll')
    timeout.current = setTimeout(() => {
      setShow(false)
    }, 500)
  }

  useEffect(() => {
    if (open) {
      openAnimate()
    } else {
      closeAnimate()
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setOpen(false)
      closeAnimate()
    }
  }, [router.asPath])

  return (
    <>
      <div
        className={cx(
          styles.offcanvas,
          {
            [styles.open]: open,
            [styles.right]: right
          },
          className
        )}
      >
        {close && (
          <div className={styles.close}>
            <button onClick={() => setOpen(!open)}>
              <Icon icon="Times" size="4x" />
            </button>
          </div>
        )}
        {show ? children : null}
      </div>
      <div
        onClick={() => setOpen(!open)}
        className={cx(styles.offcanvasOverlay, {
          [styles.open]: open
        })}
      ></div>
    </>
  )
}
