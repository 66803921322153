import cx from 'clsx'
import Icon from '@/components/common/icon'
import styles from './input.module.scss'

const Input = ({
  value,
  onChange,
  placeholder,
  label,
  onEnter,
  autoComplete,
  type = 'text',
  required = false,
  disabled = false
}) => {
  const handleKeyPress = ({ key }) => {
    onEnter && key === 'Enter' && onEnter()
  }
  const hasIcon = type == 'search' ? true : false

  return (
    <div
      className={cx(styles.root, {
        [styles.hasIcon]: hasIcon
      })}
    >
      {type === 'checkbox' ? (
        <label className={styles.checkbox}>
          <input
            type={type}
            onChange={() => onChange(!value)}
            required={required}
            disabled={disabled}
            checked={value}
          />
          <span>{label ? label : placeholder}</span>
        </label>
      ) : type === 'textarea' ? (
        <>
          <label
            className={cx('d-block mb-1', {
              ['sr-only']: !label
            })}
          >
            {label ? label : placeholder}
          </label>
          <textarea
            type={type}
            className={styles.input}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onKeyDown={handleKeyPress}
          />
        </>
      ) : (
        <>
          <label
            className={cx('d-block mb-1', {
              ['sr-only']: !label
            })}
          >
            {label ? label : placeholder}
          </label>
          <input
            type={type}
            className={styles.input}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onKeyDown={handleKeyPress}
            autoComplete={autoComplete}
          />
          {type == 'search' && <Icon icon="Search" />}
        </>
      )}
    </div>
  )
}

export default Input
