import Link from '@/components/common/link'
import Icon from '@/components/common/icon'
import useSearch from '@/hooks/useSearch'
import styles from '../styles/search.module.scss'
import { useState } from 'react'
import { useRouter } from 'next/router'

export const Search = () => {
  const [search, setSearch, results] = useSearch()
  const [focused, setFocused] = useState(false)
  const router = useRouter()

  // Search doesn't work normally because the blur event removes the link
  // and the onClick event never fires. Instead, we read the 'href' and
  // trigger the route change ourselves.
  const blur = e => {
    const root = e.currentTarget.parentNode.parentNode
    if (root.contains(e.relatedTarget) && e.relatedTarget.href) {
      router.push(e.relatedTarget.href)
    }
    setFocused(false)
    setSearch('')
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <input
          value={search}
          onChange={e => setSearch(e.target.value)}
          className={styles.input}
          onBlur={blur}
          onFocus={() => setFocused(true)}
          onKeyPress={e => {
            if (e.key === 'Enter' && search !== '') {
              router.push(`/search?keyword=${search}`)
              setSearch('')
            }
          }}
        />
        <span className={styles.label}>
          <span>Search</span>
          <Icon icon="Search" />
        </span>
      </div>
      <div
        className={styles.results}
        style={search && focused ? { display: 'block' } : { display: 'none' }}
      >
        {results.length > 0 && (
          <div className={styles.resultSection}>
            <h5 className={styles.resultsTitle}>SELECT PRODUCT</h5>
            <ul className="list-clear-all">
              {results.map(({ handle, title }) => (
                <li key={handle} className="mb-2">
                  <Link key={handle} href={`/products/${handle}`}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
