import { useState, useEffect } from 'react'
import { setCookie, getCookie } from '@/lib/cookie'

export const cookieName = 'tdr-consent'

const defaultFlags = {
  ad_storage: 'granted',
  analytics_storage: 'granted',
  functionality_storage: 'granted', // necessary
  personalization_storage: 'granted',
  security_storage: 'granted' // necessary
}

const useGdrc = () => {
  const [show, setShow] = useState(false)
  const [flags, setFlags] = useState(defaultFlags)
  const showCookies = () => setShow(true)

  const callback = newFlags => {
    setShow(false)
    setCookie(cookieName, newFlags)
    setFlags(newFlags)
    window.gtag?.('consent', 'update', newFlags)
  }

  useEffect(() => {
    window.addEventListener('show-cookies', showCookies)
    const cookie = getCookie(cookieName)
    if (cookie) {
      const newFlags = JSON.parse(cookie)
      setFlags(newFlags)
    } else {
      showCookies()
    }
    return () => window.removeEventListener('show-cookies', showCookies)
  }, [])

  return [show, flags, callback]
}

export default useGdrc
