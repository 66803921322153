import Icon from '@/components/common/icon'
import styles from './form-error.module.scss'

const FormError = props => {
  if (!props.error || typeof props.error !== 'string') {
    return null
  }

  // NOTE: Temp amend to change an error message
  const error =
    props.error == 'Unidentified customer'
      ? 'Incorrect email or password. If you do not receive a password reset email you may need to create a new account.'
      : props.error

  return (
    <div className={styles.root}>
      <p className="mb-0">
        <span className="mr-1">
          <Icon icon="Exclamation" size="lg" />
        </span>
        <span>{error}</span>
      </p>
    </div>
  )
}

export default FormError
