import FormRegister from '@/components/forms/register'

export const RegisterForm = props => {
  return (
    <>
      <FormRegister modal={true} {...props?.global?.register} />
      <div className="text-center pt-3">
        <p className="p-sm">
          Or{' '}
          <button
            className="btn-clear text-underline p-sm p-0 color-black"
            onClick={() => props.setRegister(false)}
          >
            log in to your account
          </button>
        </p>
      </div>
    </>
  )
}
