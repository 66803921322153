import { memo } from 'react'
import styles from './picture.module.scss'
import resize from '@/lib/resize-shopify-image'

const Picture = ({ image, imageMob }) => {
  if (!image) {
    return null
  }

  const src = image?.originalSrc ?? image?.src
  const mobileSrc = imageMob?.originalSrc ?? imageMob?.src ?? src
  const alt = image?.alt || imageMob?.alt

  return (
    <picture className={styles.root}>
      {/* <source media="(min-width: 1408px)" srcSet={resize(src, { w: 2048 })} />
      <source media="(min-width: 1216px)" srcSet={resize(src, { w: 2048 })} /> */}
      <source media="(min-width: 1024px)" srcSet={resize(src, { w: 2048 })} />
      <source media="(min-width: 768px)" srcSet={resize(src, { w: 1536 })} />
      <img
        className={styles.image}
        src={resize(mobileSrc, { w: 1536 })}
        alt={alt}
      />
    </picture>
  )
}

export default memo(Picture)
