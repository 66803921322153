import NextLink from 'next/link'

export default function Link({
  href,
  children,
  tag,
  fallback,
  scroll = true,
  ...props
}) {
  const noWrap = tag === false || (!href && fallback === false)
  const Tag = `${href ? tag || 'a' : fallback || tag || 'span'}`
  const Inner = noWrap ? children : <Tag {...props}>{children}</Tag>

  return href ? (
    <NextLink scroll={scroll} href={href}>
      {Inner}
    </NextLink>
  ) : (
    Inner
  )
}
