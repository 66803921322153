import styles from './logo.module'
import cx from 'clsx'

const Logo = ({ alt }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1417 209"
    className={cx(styles.root, { [styles.alt]: alt })}
  >
    <path
      className={styles.text}
      d="M746 171.7c0 5.7-2.5 9.8-10.2 9.8h-49.5c-6.3 0-10-1.8-10-7.7s2.5-8.9 10-8.9H746zm218.2-23.4v-6.6c0-5.9 2.5-9.8 10.5-9.8h49.5c6.8 0 10.2 2 10.2 8.4 0 5.4-2.7 7.9-9.8 7.9h-60.4zm129.9-43.8v104.4h50v-76h40.9c13.8 0 20.9 4.3 20.9 17.7V209h49.9v-62.5c0-29.3-16.1-42-61.8-42zm-123.1 0c-40.2 0-56.3 13.4-56.3 43.6v20.7c0 26.1 16.1 40.2 52.4 40.2h105.8v-27.5h-95.8c-9.5 0-12.7-2.7-12.7-9.8v-4.3h75.6c26.1 0 40.9-8.2 40.9-27.7v-4.8c0-17.7-8.8-30.4-53.1-30.4zM810.5 208.9h49.9v-68.6c0-4.5 1.8-7.5 8-7.5h38.8v-28.4h-55.6c-29.7 0-41.1 11.1-41.1 29.7zM639.8 104.5V132h93.8c9.3 0 12.5 3.2 12.5 10v4h-75.4c-31.6 0-41.1 13-41.1 29.5v4.1c0 22.9 18.4 29.3 54.5 29.3h55.4c39.3 0 56.3-8.9 56.3-39.7v-24.3c0-26.1-16.1-40.4-52.7-40.4zM341 162.6c0 32.9 16.8 46.3 60.2 46.3h95.1v-28.2h-87.9c-10.4 0-17.5-4.8-17.5-19.8v-9.1c0-13.4 6.6-19.1 17.3-19.1h88.1v-28.4h-96.5c-42.7 0-58.8 17.9-58.8 46.5zm170.3-74.7v121h108.5v-28.4h-58.6V87.9zm-311.7 67.7V87.9h-28.2c-17.5 0-27.5 7-37.9 15.2L0 208.9h60.2l88.6-71.7v71.7h41.5l85.8-72.2v72.2H327v-121h-27.7c-15 0-25.4 6.1-35.9 14.8z"
    />
    <path
      className={styles.icon}
      d="M1375.9 0c-27.9 0-67.2 8.3-109.7 23.9a556.166 556.166 0 00-108.6 53.5c22.2-9.1 66.1-19.4 102.3-19.4 48.8 0 83.6 18.7 32.2 84.2C1443.4 43.2 1439.3.1 1375.9 0"
    />
  </svg>
)

export default Logo
