import styles from './basket.module.scss'
import OffCanvas from '@/components/common/off-canvas'
import Container from '@/components/basket/container'
import Empty from '@/components/basket/empty'
import Items from '@/components/basket/items'
import VoucherForm from '@/components/basket/voucher-form'
import Summary from '@/components/basket/summary'
import Submitted from '@/components/basket/submitted'
import useBasket from '@/hooks/useBasket'
import useCheckout from '@/hooks/useCheckout'
import useNav from '@/hooks/useNav'

const BasketContents = props => {
  const { items } = useBasket()
  const { inProgress } = useCheckout()

  return inProgress ? (
    <Submitted />
  ) : items.length == 0 ? (
    <Empty />
  ) : (
    <>
      <Items />
      <VoucherForm />
      <Summary {...props} />
    </>
  )
}

export default function Basket(props) {
  const [setOpen, open] = useNav('basket')

  return (
    <OffCanvas
      right
      open={open}
      setOpen={setOpen}
      className={styles.offCanvas}
      close={false}
    >
      <Container setOpen={setOpen}>
        <BasketContents {...props} />
      </Container>
    </OffCanvas>
  )
}
