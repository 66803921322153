import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { loggedInSelector } from '@/lib/store/slice/auth'
import { recall } from '@/lib/remember'

const useRedirect = ({ to, whenLoggedIn, and = true }) => {
  const router = useRouter()
  const loggedIn = useSelector(loggedInSelector)

  useEffect(() => {
    if (loggedIn == whenLoggedIn && and == true) {
      // const params = new URLSearchParams(window.location.search)
      // const newUrl = params.get('redirect') ?? to
      const newUrl = recall('redirect') ?? to
      router.push(newUrl)
    }
  }, [loggedIn, router])
}

export default useRedirect
