import cx from 'clsx'
import Icon from '@/components/common/icon'
import styles from './quantity.module'

const Quantity = ({ quantity, setQuantity, disabled, small = false }) => {
  const subtract = () => parseAndSet(quantity - 1)
  const add = () => parseAndSet(Number(quantity) + 1)

  const parseAndSet = val => {
    if (parseInt(val) == val && val >= 1 && !disabled) {
      setQuantity(val)
    }
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.small]: small,
        [styles.disabled]: disabled
      })}
    >
      <div className={styles.controls} onClick={subtract}>
        <Icon icon="Minus" />
      </div>

      <div className={styles.value}>{quantity}</div>
      <div className={styles.controls} onClick={add}>
        <Icon icon="Plus" />
      </div>
    </div>
  )
}

export default Quantity
