import cx from 'clsx'
import styles from './modal.module.scss'
import Logo from '@/components/common/logo'
import Icon from '@/components/common/icon'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useRouter } from 'next/router'

const Portal = ({ children, inertSelector }) => {
  const [el, setEl] = useState(null)

  useEffect(() => {
    // @TODO move to function. reuse with off-canvas
    const scrollApplied =
      document.documentElement.classList.contains('no-scroll')
    !scrollApplied && document.documentElement.classList.add('no-scroll')

    const inertEl = document.querySelector(inertSelector)
    inertEl && inertEl.setAttribute('inert', true)

    if (!el) {
      let _el = document.createElement('div')
      document.body.appendChild(_el)
      setEl(_el)
    }

    return () => {
      !scrollApplied && document.documentElement.classList.remove('no-scroll')
      inertEl && inertEl.removeAttribute('inert')
      el && document.body.removeChild(el)
    }
  }, [el, inertSelector])

  return el ? createPortal(children, el) : null
}

const Modal = ({
  children,
  open,
  setOpen,
  logo = false,
  large = false,
  klarna = false
}) => {
  const [ready, setReady] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setTimeout(
      () => {
        setReady(open)
      },
      open ? 10 : 500
    )
  }, [open])

  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [router.asPath])

  if (!open && !ready) {
    return false
  }

  return (
    <Portal inertSelector="#__next">
      <div
        className={cx(styles.root, {
          [styles.open]: open && ready,
          [styles.large]: large
        })}
      >
        <div className={styles.modal}>
          {!logo && (
            <div className={styles.close}>
              <button onClick={() => setOpen(false)}>
                <Icon icon="Times" size="2x" />
              </button>
            </div>
          )}
          <div className={styles.inner}>
            {logo && (
              <div
                className={cx(styles.header, {
                  [styles.hasBorder]: logo
                })}
              >
                <div>{klarna ? <Icon icon="KlarnaLogo" /> : <Logo />}</div>
                <button
                  className="btn-clear d-flex align-items-center color-black"
                  onClick={() => setOpen(false)}
                >
                  <Icon icon="Times" size="lg" />
                  <span className="text-uppercase pl-2">Close</span>
                </button>
              </div>
            )}
            <div className={styles.content}>{children}</div>
          </div>
        </div>
        <div
          className={styles.modalBackground}
          onClick={() => setOpen(false)}
        ></div>
      </div>
    </Portal>
  )
}

export default Modal
