import cx from 'clsx'
import Link from '@/components/common/link'
import styles from '../category-accordion.module.scss'
import Icon from '@/components/common/icon'
import { CategoryGroup } from './category-group'
import { useRef, useState, useEffect } from 'react'

export const CategoryItem = ({
  item,
  itemKey,
  active,
  onToggle,
  onMouseEnter
}) => {
  const contentEl = useRef()
  const [groupClicked, setGroupClicked] = useState(null)
  const [height, setHeight] = useState(0)
  const [parentHeight, setParentHeight] = useState(0)
  const [childHeight, setChildHeight] = useState(0)

  // child item clicked
  const handleGroupToggle = (index, height) => {
    if (groupClicked === index) {
      setGroupClicked('0')
    } else {
      setGroupClicked(index)
    }

    setChildHeight(height)
  }

  // Parent item clicked
  const handleParentToggle = (e, index) => {
    e.preventDefault()
    onToggle()
  }

  // Parent item without link
  const preventLink = e => {
    e.preventDefault()
  }

  // Save parent height when active changes
  useEffect(() => {
    if (contentEl.current) {
      setParentHeight(contentEl.current.scrollHeight)
    }
  }, [active])

  // Set parent height to parent
  useEffect(() => {
    if (contentEl.current) {
      setHeight(parentHeight)
    }
  }, [parentHeight])

  // Set parent height to parent + child
  useEffect(() => {
    if (contentEl.current) {
      setHeight(parentHeight + childHeight)
    }
  }, [childHeight])

  return (
    <li
      className={cx(styles.item, styles.parent, {
        [styles.active]: active,
        [styles.hasAccordion]: item.items?.length > 0
      })}
    >
      <Link
        href={item.url + "?shown=24&sort=featured"}
        onClick={e => {
          item.items?.length > 0
            ? handleParentToggle(e, itemKey)
            : !item.url
            ? preventLink
            : undefined
        }}
        onMouseEnter={() =>
          onMouseEnter && item.image
            ? onMouseEnter({
                src: item.image.src,
                alt: item.image.alt
              })
            : ''
        }
      >
        <span className={styles.parentTitle}>{item.title}</span>
        {item.items?.length > 0 && (
          <span className="ml-2">
            {active ? <Icon icon="AngleUp" /> : <Icon icon="AngleDown" />}
          </span>
        )}
      </Link>
      {item.items && (
        <div
          ref={contentEl}
          className={cx(styles.inner, styles.innerGroup)}
          style={active ? { height: height } : { height: '0px' }}
        >
          <ul
            className={cx(styles.list, {
              [styles.listSingle]: item.items?.length == 1
            })}
          >
            {item.items.map((group, i) => (
              <CategoryGroup
                onGroupToggle={handleGroupToggle}
                active={groupClicked === i}
                group={group}
                groupKey={i}
                key={i}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  )
}
