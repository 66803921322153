import Button from '@/components/common/button'
import Banner from '@/components/global/plus-banner'
import Icon from '@/components/common/icon'
import useVoucher from '@/hooks/useVoucher'
import useCheckout from '@/hooks/useCheckout'
import price from '@/lib/price'
import styles from './summary.module'
import useBasket from '@/hooks/useBasket'


const Summary = props => {
  const { items, count, setQuantity, removeItem } = useBasket()
  const { removeVoucher } = useVoucher()
  const {
    checkStock,
    inProgress,
    subtotal,
    discounts,
    total,
    updating,
    productsFailed
  } = useCheckout()

  const discountTypes = [
    'McLaren Plus',
    'McLaren Staff',
    'McLaren Advisory Group',
    'Papaya Members',
    'McLaren Partners'
  ]

  const hasPlus = discounts?.find(o => discountTypes.includes(o.title))
    ? true
    : false

  let basketMessages = []
  let giftTriggerItems = 0
  let giftTriggerItemsArray = []
  let giftFound = false

  let mixAndMatchItemsThreeForTwo = 0
  let mixAndMatchItemsTwoForOne = 0
  let mixAndMatchItemsFreeGift = 0
  // Tally up the amount of different 3for2 items
  items.map((item, i) => {

    let offerDiscountExists = item.discounts?.map(discount => {
      return discount.discountType == 1 && discount.value == 100 ? true : false
    })

    item.labels.map((label, i) => {
      if(label.toUpperCase() == '3 FOR 2' && offerDiscountExists) {
        if(item.quantity > 1) {
          mixAndMatchItemsThreeForTwo += item.quantity
        } else {
          mixAndMatchItemsThreeForTwo += 1
        }
      }
      if(label.toUpperCase() == '2 FOR 1' && offerDiscountExists) {
        if(item.quantity > 1) {
          mixAndMatchItemsTwoForOne += item.quantity
        } else {
          mixAndMatchItemsTwoForOne += 1
        }
      }

      if(label.toUpperCase() == 'FREE GIFT') {
          giftTriggerItems += 1
        giftTriggerItemsArray.push(['1'])
      }

      if(label.toUpperCase() == 'YOUR FREE GIFT' && offerDiscountExists) {
        giftFound = true
      }
    })
  })

  if(mixAndMatchItemsThreeForTwo > 0 && mixAndMatchItemsThreeForTwo < 3) {
    basketMessages.push({ item: '', label: '3 FOR 2', currently: mixAndMatchItemsThreeForTwo, required: 3 })
  }

  if(mixAndMatchItemsTwoForOne > 0 && mixAndMatchItemsTwoForOne < 2) {
    basketMessages.push({ item: '', label: '2 FOR 1', currently: mixAndMatchItemsTwoForOne, required: 2 })
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-1 p-xl">
        <span className="text-medium">Subtotal</span>
        <span className="text-bold pl-4" data-ge-basket-subtotals>
          {price(subtotal)}
        </span>
      </div>
      {discounts && discounts.length > 0 && (
        <>
          {discounts.map((discount, i) => (
            <div
              key={i}
              className="d-flex justify-content-between mb-1"
              data-discount-row
            >
              <span className="p-sm text-medium d-flex align-items-center">
                <div className="ml-1">
                  <Icon icon="Tag" />
                </div>
                <span className="ml-1">{discount.title}</span>
                {/* <span className="ml-1">{discount.description}</span> */}
                {discount.type == 'voucher' && (
                  <button
                    className="btn-clear px-2"
                    onClick={removeVoucher(discount.title)}
                  >
                    <Icon icon="Times" />
                  </button>
                )}
              </span>
              <span
                className="text-bold pl-4 text-nowrap"
                data-ge-basket-discount-title={discount.title}
                data-ge-basket-discount-summary
              >
                -{price(discount.amount)}
              </span>
            </div>
          ))}
          <div className="d-flex justify-content-between p-xl">
            <span className="text-medium">
              Total {discounts.length && <span>After Discount(s)</span>}
            </span>
            <span className="text-bold pl-4" data-ge-basket-totals>
              {price(total)}
            </span>
          </div>
        </>
      )}
      <div className="mt-5">
        {productsFailed.length > 0 && (
          <div className={styles.errors}>
            <p className="text-bold text-uppercase">
              We&apos;re sorry, some of the products in your basket are no
              longer in stock:
            </p>
            <ul className="list-clear-all">
              {productsFailed.map((p, i) => (
                <li key={i}>
                  <p className="mb-0 mt-1 d-flex align-items-center p-sm">
                    <Icon icon="TimesCircle" />
                    <span className="ml-1 text-uppercase text-bold">
                      {p.title}
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {basketMessages.length !== 0 && (
        <div className="product-message-container">
          {basketMessages.map((item, i) => {
            let productMultipleString = ''
            if(item.required - item.currently > 1) productMultipleString = 's'
            return <p className="product-message">{item.label + ': ' + 'Add ' + (item.required - item.currently) + ' more qualifying product'+ productMultipleString} to claim this offer</p>
          })}
        </div>)}

        {giftFound == false && giftTriggerItems > 0 && (
          <div className="product-message-container">
            {giftTriggerItemsArray.map((item, i) => {
              return <p className="product-message">FREE GIFT: Dont forget to claim your free gift above</p>
            })}
          </div>
        )}

        <Button
          onClick={checkStock}
          className="w-100"
          data-ge-checkout-button
          disabled={inProgress || updating}
        >
          {inProgress ? 'Please wait' : 'Checkout Now'}
        </Button>
      </div>
      {!hasPlus && (
        <Banner
          title={props?.global?.sitewide?.mclarenPlusText}
          image={props?.global?.sitewide?.mclarenPlusImage}
        />
      )}
    </>
  )
}

export default Summary
