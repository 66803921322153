import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getCountries from '@/lib/storefront/get-countries'

const initialState = []

// slice
export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    'fetch/fulfilled': (_, action) => action.payload
  }
})

// selectors
export const countriesSelector = state => state.countries

// async
export const fetchCountries = createAsyncThunk(
  'countries/fetch',
  getCountries,
  {
    condition: (_, { getState }) => {
      const { countries } = getState()
      return !countries.length
    }
  }
)

export default countriesSlice.reducer
