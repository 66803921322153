import { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const SEARCH_PRODUCTS_MAX = 10

const useSearch = () => {
  const [query, setQuery] = useState('')
  const [fuses, setFuses] = useState(null)
  const [results, setResults] = useState([])

  useEffect(() => {
    if (fuses || !query) return
    fetch(`${publicRuntimeConfig.api}/search-static`)
      .then(res => res.json())
      .then(res => {
        setFuses(
          new Fuse(res.products, {
            includeScore: true,
            ignoreLocation: false,
            threshold: 0.3,
            keys: [
              {
                name: 'title',
                weight: 0.7
              },
              {
                name: 'description',
                weight: 0.3
              }
            ]
          })
        )
      })
  }, [query])

  useEffect(() => {
    if (!query || !fuses) return setResults([])

    const filtered = fuses
      .search(query)
      .slice(0, SEARCH_PRODUCTS_MAX)
      .map(({ item, score }) => ({
        title: item.title,
        handle: item.handle,
        score: score
      }))

    setResults(filtered)
  }, [query, fuses])

  return [query, setQuery, results]
}

export default useSearch
