import gql from './client'

// https://shopify.dev/api/storefront/reference/customers/customeraccesstokencreate
const query = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const responseHandler = async (
  { customerAccessTokenCreate },
  { email, remember }
) => {
  const { customerAccessToken, customerUserErrors } = customerAccessTokenCreate
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
  if (!customerAccessToken) {
    throw new Error('No Access Token')
  }

  // @TODO use redux event to save
  return { ...customerAccessToken, email, remember }
}

export default query(responseHandler)
