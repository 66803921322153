import styles from './footer.module.scss'
import LinkList from '@/components/common/link-list'
import Icon from '@/components/common/icon'
import useControlledByGlobale from '@/hooks/intl/useControlledByGlobale'
import Button from '@/components/common/button'

const Footer = props => {
  const isGe = useControlledByGlobale()

  const showCookieBanner = () =>
    window.dispatchEvent(new CustomEvent('show-cookies'))

  return (
    <div className="py-6">
      {props.topNavigation && (
        <div className="mb-5">
          <LinkList
            items={props.topNavigation}
            row={true}
            listClass={styles.topNavigation}
          />
        </div>
      )}
      {props.bottomNavigation && (
        <div className="mb-5">
          <LinkList
            items={props.bottomNavigation}
            row={true}
            listClass={styles.bottomNavigation}
          />
        </div>
      )}
      <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-center align-items-center">
        {props.socialList && (
          <div className="py-1">
            <ul className={styles.listNavigation}>
              {props.socialList.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Icon icon={item.icon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isGe && props.socialList && props.paymentList && (
          <div className="mx-5 d-none d-lg-block">
            <span className="color-primary h4 mb-0 text-normal">|</span>
          </div>
        )}
        {!isGe && props.paymentList && (
          <div className="py-1">
            <ul className={styles.listNavigation}>
              {props.paymentList.map((item, i) => (
                <li key={i}>
                  <Icon icon={item.icon} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {props.externalNavigation && (
          <>
            <div className="mx-5 d-none d-lg-block">
              <span className="color-primary h4 mb-0 text-normal">|</span>
            </div>
            <div className="py-1">
              <LinkList
                items={props.externalNavigation}
                row={true}
                listClass={styles.externalNavigation}
              />
            </div>
          </>
        )}
        <div className="mx-lg-2">
          <Button
            className={styles.cookieSettings}
            text={true}
            onClick={showCookieBanner}
          >
            Cookie Settings
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Footer
