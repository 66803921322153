import Link from '@/components/common/link'
import useSearch from '@/hooks/useSearch'
import styles from '../styles/search-mobile.module.scss'
import { useRouter } from 'next/router'

export const SearchMobile = () => {
  const [search, setSearch, results] = useSearch()
  const router = useRouter()

  return (
    <div className={styles.root}>
      <input
        value={search}
        onChange={e => setSearch(e.target.value)}
        onKeyPress={e => {
          if (e.key === 'Enter' && search !== '' || e.key == '13' && search !== '') {
            router.push(`/products?keyword=${search}`)
            setSearch('')
          }
        }}
        className={styles.input}
        placeholder="Search Products"
      />
      <div
        className={styles.results}
        style={search ? { display: 'block' } : { display: 'none' }}
      >
        {results.length > 0 && (
          <>
            <h5 className="mt-4">SELECT PRODUCT</h5>
            <ul className="list-clear-all">
              {results.map(({ handle, title }) => (
                <li key={handle} className="mb-2">
                  <Link key={handle} href={`/products/${handle}`}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div
        className={styles.beforeSearch}
        style={search ? { display: 'none' } : { display: 'block' }}
      >
        To begin searching, type your query into the input field above
      </div>
    </div>
  )
}
