const SizeGuideBaby = () => {
  return (
    <>
      <h3>BABY SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Age (months)</th>
              <th>Chest (cm)</th>
              <th>Waist (cm)</th>
              <th>Hip (cm)</th>
              <th>Height (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0-3</td>
              <td>41-43</td>
              <td>42-45</td>
              <td>42-45</td>
              <td>60-65</td>
            </tr>
            <tr>
              <td>3-6</td>
              <td>43-45</td>
              <td>45-47</td>
              <td>45-47</td>
              <td>65-70</td>
            </tr>
            <tr>
              <td>6-9</td>
              <td>45-47</td>
              <td>47-48</td>
              <td>47-48</td>
              <td>70-75</td>
            </tr>
            <tr>
              <td>9-12</td>
              <td>47-48</td>
              <td>48-50</td>
              <td>48-50</td>
              <td>75-80</td>
            </tr>
            <tr>
              <td>12-18</td>
              <td>48-50</td>
              <td>50-52</td>
              <td>50-52</td>
              <td>80-85</td>
            </tr>
            <tr>
              <td>18-24</td>
              <td>50-52</td>
              <td>52-54</td>
              <td>52-54</td>
              <td>85-90</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideBaby.type = 'baby'

export default SizeGuideBaby
