exports.getCookie = name => {
  return document.cookie
    ?.split('; ')
    .find(o => o.includes(`${name}=`))
    ?.split('=')[1]
}

exports.setCookie = (name, value, days = 365) => {
  let date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()

  document.cookie = `${name}=${JSON.stringify(
    value
  )}; ${expires}; path=/; secure`
}
