export const fromItems = items =>
  items
    .map(i => {
      const id = Number(i.id).toString(36)
      const qty = Number(i.quantity).toString(36)
      const idLength = Number(id.length).toString(36)
      const qtyLength = Number(qty.length).toString(36)
      return idLength + qtyLength + id + qty
    })
    .join('')

export const toItems = hash => {
  let p = 0
  const items = []

  while (p < hash.length) {
    let idLength = parseInt(hash[p], 36)
    let qtyLength = parseInt(hash[p + 1], 36)
    let id = parseInt(hash.slice(p + 2, p + 2 + idLength), 36)
    let quantity = parseInt(
      hash.slice(p + 2 + idLength, p + 2 + idLength + qtyLength),
      36
    )

    if (!isNaN(id) && !isNaN(quantity)) {
      items.push({
        id: String(id),
        quantity
      })
    }

    p += idLength + qtyLength + 2
  }

  return items
}
