import { useState, useEffect } from 'react'
import { currencyList } from '@/lib/globale'
import { setCookie } from '@/lib/cookie'

const getGEData = () => {
  const countries =
    window.GEM_Services.ConfigurationsService.instance.MerchantConfigurations
      .MerchantCountries

  const country = window.GlobalE.Country || 'GB'
  const currency = window.GlobalE.Currency || 'GBP'
  const isOperatedByGlobale = countries[country].IsOperatedByGlobalE

  return { countries, country, currency, isOperatedByGlobale }
}

const useGlobale = open => {
  const [countries, setCountries] = useState({})
  const [country, setCountry] = useState('GB')
  const [currency, setCurrency] = useState('GBP')
  const [isOperatedByGlobale, setIsOperatedByGlobale] = useState(false)

  // update content on open (not on page load cause GE might not be ready)
  useEffect(() => {
    if (!open) return
    const geData = getGEData()
    setCountries(geData.countries)
    setCountry(geData.country)
    setCurrency(geData.currency)
    setIsOperatedByGlobale(geData.isOperatedByGlobale)
  }, [open])

  // when the country changes, set the currency
  useEffect(() => {
    if (typeof window === 'undefined' || !open) return
    setIsOperatedByGlobale(countries[country].IsOperatedByGlobalE)
    setCurrency(countries[country].DefaultCurrencyCode)
  }, [country])

  // save hook
  const save = () => {
    setCookie('GlobalE_Data', {
      countryISO: country,
      currencyCode: currency
    })

    window.GlobalE.UpdateCustomerInfo(
      country,
      currency,
      true,
      window.location.href
    )
  }

  return [
    Object.values(countries),
    isOperatedByGlobale
      ? currencyList
      : [{ label: 'Pound Sterling', value: 'GBP' }],
    country,
    setCountry,
    currency,
    setCurrency,
    save
  ]
}

export default useGlobale
