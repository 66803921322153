import cx from 'clsx'
import Link from '@/components/common/link'
import Icon from '@/components/common/icon'
import styles from './link-list.module.scss'

const LinkList = ({ items, row, arrow, listClass }) => {
  return (
    <ul
      className={cx(styles.list, listClass, {
        [styles.row]: row
      })}
    >
      {items.map((item, i) => (
        <li key={i}>
          <Link
            href={item.url}
            className={cx({
              ['d-flex align-items-center justify-content-between']: arrow
            })}
          >
            <span>{item.title}</span>
            {arrow && (
              <span className={styles.arrow}>
                <Icon icon="ArrowRight" />
              </span>
            )}
          </Link>
          {item.children}
        </li>
      ))}
    </ul>
  )
}

export default LinkList
