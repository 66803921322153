import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { emailSelector } from '@/lib/store/slice/customer'
import { itemsSelector } from '@/lib/store/slice/cart'
import Input from '@/components/common/input'
import Button from '@/components/common/button'
import Icon from '@/components/common/icon'
import useVoucher from '@/hooks/useVoucher'
import styles from './voucher.module'

const VoucherForm = () => {
  const [voucherInput, setVoucherInput] = useState('')
  const [showError, setShowError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const setVoucherInputUpper = text => setVoucherInput(text.toUpperCase())
  const { addVoucher, inProgress, voucherError } = useVoucher()
  const userEmail = useSelector(emailSelector)
  const basketItems = useSelector(itemsSelector)

  // Check validity of voucher entered
  const checkVoucher = () => {
    setShowError(false)
    setErrorText('')

    if (!voucherInput) {
      setErrorText('No voucher code entered')
      setShowError(true)
      return null
    }

    if (!basketItems) {
      setErrorText('No items in basket')
      setShowError(true)
      return null
    }

    addVoucher(voucherInput, basketItems, userEmail)
    setVoucherInput('')
  }

  // On error returned from submission
  useEffect(() => {
    if (voucherError) {
      setErrorText(voucherError)
      setShowError(true)
    }
  }, [voucherError])

  return (
    <div className={styles.root}>
      <hr className="my-4" />
      <div className="d-flex flex-column flex-sm-row">
        <div className="flex-fill">
          <Input
            placeholder="Add voucher code"
            value={voucherInput}
            onChange={setVoucherInputUpper}
            disabled={inProgress}
            onEnter={checkVoucher}
          />
        </div>
        <div className="pl-sm-3 mt-2 mt-sm-0">
          <Button onClick={checkVoucher} disabled={inProgress}>
            Add
          </Button>
        </div>
      </div>
      {showError && (
        <p className="mb-0 mt-3 color-highlight d-flex align-items-center">
          <Icon icon="TimesCircle" />
          <span className="ml-1 p-sm text-bold">{errorText}</span>
        </p>
      )}
      <hr className="my-4" />
    </div>
  )
}

export default VoucherForm
