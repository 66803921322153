import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const checkoutRequest = data =>
  fetch(`${publicRuntimeConfig.api}/checkout`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json'
    }
  }).then(res => res.json())

export default checkoutRequest
