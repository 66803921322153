import { useState } from 'react'
import Button from '@/components/common/button'
import Modal from '@/components/common/modal'
import Variations from '@/components/product/variations'
import styles from '../single.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { addItem } from '@/lib/store/slice/cart'

// NOTE: Need to change the button area to include add-to-basket
// NOTE: Need to add 'selected' code for variations
// NOTE: Need to add some extra meta data to gift products so we can display differently in cart

export const GiftModal = ({ open, setModal, product }) => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState(0)
  const addToBasket = () => {
    const variant = product.product.variants[selected]
    const collectionsSimple = product.product.collections.map(c => c.handle)
    const itemLabels = ['your free gift']
    setModal(false)
    // setSelected(variant)
    dispatch(
      addItem({
        sku: variant.sku,
        handle: product.product.handle,
        title: product.product.title,
        image: product.product.images[0].originalSrc,
        parentId: product.product.id,
        discounts: [],
        id: variant.id,
        variant: variant.title,
        options: variant.selectedOptions,
        price: Number(variant.price),
        collections: collectionsSimple,
        tags: product.product.tags,
        quantity: 1,
        labels: itemLabels,
        gift: true
      })
    )
  }

  if (!product) {
    return false
  }

  return (
    <Modal open={open} setOpen={setModal}>
      <h3 className="mb-7">
        CLAIM YOUR <span className="color-highlight">FREE</span> GIFT!
      </h3>
      <form>
        <div className="row mb-6">
          <div className="col-12 col-lg-6 mb-5 mb-lg-0">
            {product.product.images && (
              <div className={styles.image}>
                <img
                  src={product.product.images[0].originalSrc}
                  alt={product.product.images[0].altText}
                />
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6">
            <h5>{product.product.title}</h5>
            <p className="p-sm">{product.product.description}</p>
          </div>
        </div>
        <div className="mb-6">
          <Variations
            options={product.product.options}
            variants={product.product.variants}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <Button className="w-100" onClick={addToBasket}>
          Add to Basket
        </Button>
      </form>
    </Modal>
  )
}
