import gql from './client'

// example input
const variables = {
  input: {
    // acceptsMarketing: true,
    email: 'user@example.com',
    firstName: 'optional',
    lastName: 'optional',
    password: 'HiZqFuDvDdQ7',
    phone: 'optionalString'
  }
}

// https://shopify.dev/api/storefront/reference/customers/customeraddressedit
const query = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
        address1
        address2
        city
        zip
        company
        country
        firstName
        lastName
        phone
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerAddressUpdate }) => {
  const { customerAddress, customerUserErrors } = customerAddressUpdate
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
  return customerAddress
}

export default query(responseHandler)
