const SizeGuideJunior = () => {
  return (
    <>
      <h3>JUNIOR SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Chest (cm)</th>
              <th>Waist (cm)</th>
              <th>Hip (cm)</th>
              <th>Height (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>JS (8-9)</td>
              <td>66-69</td>
              <td>61.5-65</td>
              <td>71-74.5</td>
              <td>128-137</td>
            </tr>
            <tr>
              <td>JM (10-11)</td>
              <td>69-75</td>
              <td>65-69</td>
              <td>74.5-79.5</td>
              <td>137-147</td>
            </tr>
            <tr>
              <td>JL (12-13)</td>
              <td>75-81.5</td>
              <td>69-72.5</td>
              <td>79.5-84.5</td>
              <td>147-158</td>
            </tr>
            <tr>
              <td>JXL (14)</td>
              <td>81.5-88.5</td>
              <td>72.5-75.5</td>
              <td>84.5-89.5</td>
              <td>158-170</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideJunior.type = 'junior'

export default SizeGuideJunior
