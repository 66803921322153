import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import customerAccessTokenCreate from '../../storefront/customer-access-token-create'
import customerCreate from '../../storefront/customer-create'
import customerRecover from '../../storefront/customer-recover'
import customerResetByUrl from '../../storefront/customer-reset-by-url'
import customerActivateByUrl from '../../storefront/customer-activate-by-url'

const initialState = {
  accessToken: null,
  expiresAt: null,
  // @TODO use this when saving (loading?) data.
  // local session storage?
  remember: false
}

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.accessToken = null
      state.expiresAt = null
      state.customer = initialState.customer
    },
    'login/fulfilled': (state, action) => {
      const { accessToken, expiresAt, remember } = action.payload
      state.accessToken = accessToken
      state.expiresAt = new Date(expiresAt).getTime()
      state.remember = remember
    },
    'reset/fulfilled': (state, action) => {
      const { accessToken, expiresAt } = action.payload
      state.accessToken = accessToken
      state.expiresAt = new Date(expiresAt).getTime()
    },
    'activate/fulfilled': (state, action) => {
      const { accessToken, expiresAt } = action.payload
      state.accessToken = accessToken
      state.expiresAt = new Date(expiresAt).getTime()
    }
  },
  extraReducers: {
    'customer/password/update/fulfilled': (state, action) => {
      // @TODO disable this to test what happens if fetch fails (force logout)
      const { accessToken, expiresAt } = action.payload.customerAccessToken
      state.accessToken = accessToken
      state.expiresAt = new Date(expiresAt).getTime()
    }
  }
})

// selectors
export const loggedInSelector = state => {
  const { accessToken, expiresAt } = state.auth
  return !!(accessToken && expiresAt > Date.now())
}

// async
export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password, remember }) => {
    const response = await customerAccessTokenCreate({
      input: { email, password }
    })
    return { ...response, email, remember }
  },
  {
    condition: args => {
      return args.email && args.password
    }
  }
)

export const register = createAsyncThunk('auth/register', customerCreate)
export const forgot = createAsyncThunk('auth/forgot', customerRecover)
export const reset = createAsyncThunk('auth/reset', customerResetByUrl)
export const activate = createAsyncThunk('auth/activate', customerActivateByUrl)

export const { logout, clearError } = authSlice.actions
export default authSlice.reducer
