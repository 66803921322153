import Modal from '@/components/common/modal'
import Button from '@/components/common/button'
import useNav from '@/hooks/useNav'
import useGlobale from '@/hooks/useGlobale'

const CountrySwitcher = () => {
  const [setOpen, open] = useNav('country')
  const [
    countries,
    currencies,
    country,
    setCountry,
    currency,
    setCurrency,
    save
  ] = useGlobale(open)

  return (
    <Modal open={open} setOpen={setOpen}>
      <h2 style={{ textAlign: 'center' }}>NOW SHIPPING WORLDWIDE</h2>

      <div className="mt-8 mb-1">Change your shipping country</div>

      <select
        style={{ padding: 8, width: '100%' }}
        value={country}
        onChange={e => setCountry(e.target.value)}
      >
        {countries.map(country => (
          <option key={country.Code} value={country.Code}>
            {country.Name}
          </option>
        ))}
      </select>

      <div className="mt-6 mb-1">Change currency</div>

      <select
        className="mb-6"
        style={{ padding: 8, width: '100%' }}
        value={currency}
        onChange={e => setCurrency(e.target.value)}
      >
        {currencies.map(currency => (
          <option key={currency.value} value={currency.value}>
            {currency.label}
          </option>
        ))}
      </select>

      <Button expand onClick={save}>
        Save
      </Button>
    </Modal>
  )
}

export default CountrySwitcher
