import { configureStore } from '@reduxjs/toolkit'
import { loadState } from './localStorage'
import auth from './slice/auth'
import customer from './slice/customer'
import nav from './slice/nav'
import cart from './slice/cart'
import countries from './slice/countries'
import intl from './slice/intl'
import summary from './slice/summary'
import storefront from './slice/storefront'
import middleware from './middleware'
import init from './init'

export const store = configureStore({
  reducer: {
    auth,
    countries,
    customer,
    nav,
    intl,
    cart,
    summary,
    storefront
  },
  preloadedState: loadState(),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware)
})

init(store)
