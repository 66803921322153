export const pageView = page => {
  event('VirtualPageView', { page })
}

export const event = (event, params) => {
  window?.dataLayer?.push({
    event,
    ...params
  })
}
