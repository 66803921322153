import gql from './client'
import newsletter from '@/lib/api/newsletter'

// example input
const variables = {
  input: {
    // acceptsMarketing: true,
    email: 'user@example.com',
    firstName: 'optional',
    lastName: 'optional',
    password: 'HiZqFuDvDdQ7',
    phone: 'optionalString',
    acceptsMarketing: false
  }
}

// https://shopify.dev/api/storefront/reference/customers/customerupdate
const query = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customer {
        firstName
        lastName
        email
        phone
        acceptsMarketing
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// @TODO split into 2 requests - profile update & password change
// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerUpdate }) => {
  const { customer, customerAccessToken, customerUserErrors } = customerUpdate
  if (customerUserErrors.length) {
    throw customerUserErrors[0]
  }

  newsletter(customer)

  return { customer, customerAccessToken }
}

export default query(responseHandler)
