import { useState } from 'react'
import Input from '@/components/common/input'
import Button from '@/components/common/button'
import FormError from '@/components/common/form-error'
import useForgot from '@/hooks/auth/useForgot'

const FormForgot = ({ modal = false }) => {
  const [error, inProgress, action] = useForgot()
  const [email, setEmail] = useState('')

  const onSubmit = e => {
    e.preventDefault()
    action({ email })
    setEmail('')
    // close form
  }

  const cols = modal ? 'col-12 mb-3' : 'col-12 col-lg-6 mb-3'

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <Input
            type="email"
            placeholder="Email Address"
            value={email}
            required={true}
            onChange={setEmail}
          />
        </div>
        <div className="row row-sm">
          <div className={cols}>
            <Button
              form={true}
              type="submit"
              className="w-100"
              disabled={inProgress}
            >
              Reset Password
            </Button>
          </div>
        </div>
        {error && <FormError error={error} />}
      </form>
    </>
  )
}

export default FormForgot
