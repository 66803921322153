import Header from '@/components/global/header'
import Footer from '@/components/global/footer'
import ScrollToTop from '@/components/global/scroll-to-top'
import Basket from '@/containers/basket'
import Notifier from '@/components/global/notifier'
import CookieBanner from '@/components/global/cookie-banner'
import CountrySwitcher from '@/components/modals/country-switcher'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useNav from '@/hooks/useNav'

export default function Layout({ children, ...props }) {
  const router = useRouter()
  const [closeAll] = useNav()

  useEffect(() => {
    closeAll()
  }, [router.route])

  const headerProps = {
    header: props.header,
    global: props.global
  }

  const globalProps = {
    global: props.global
  }

  return (
    <>
      <CookieBanner />
      <Notifier />
      <Basket {...globalProps} />
      <CountrySwitcher />
      <Header {...headerProps} />
      <main>{children}</main>
      <Footer {...props.footer} />
      <ScrollToTop />
    </>
  )
}
