import { useState, useEffect } from 'react'

const useScroll = () => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    const listener = () => {
      setScroll(window.scrollY)
    }
    window.addEventListener('scroll', listener, { passive: true })
    return () => window.removeEventListener('scroll', listener)
  })
  return scroll
}

export default useScroll
