import { useSelector, useDispatch } from 'react-redux'
import { navSelector, toggle, closeAll } from '@/lib/store/slice/nav'

const useNav = item => {
  const dispatch = useDispatch()
  const navState = useSelector(navSelector)

  if (!item) {
    const closeAllAction = () => dispatch(closeAll())
    return [closeAllAction, navState]
  }

  const toggleItem = () => dispatch(toggle({ item }))

  return [toggleItem, navState[item]]
}

export default useNav
