import * as countries from '@/lib/mock/country-codes'

/*
 * Find Available Stock
 *
 * This function takes the "storeAvailability" Shopify pick-up data
 * to determine whether a product is in stock in a particular DC.
 *
 * @ helper function for below functions (no export)
 */

const findAvailable = (name, locations) => {
  if (!name || !locations) return false

  return locations.some(el => el.location.name == name && el.available == true)
}

/*
 * Check Location Stock
 *
 * This function works out which DC the system should be checking
 * stock from, based off the GE country cookie code.
 *
 * @ /hooks/product/useInStock
 */

export const checkLocationStock = (locations, countryCode) => {
  if (!locations || !countryCode) return false

  // Default to false
  let inStock = false

  // Check if the customer is in an international inventory location
  if (countries.AU.some(c => c == countryCode)) {
    inStock = findAvailable('SEKO Australia', locations)
  } else if (countries.NL.some(c => c == countryCode)) {
    inStock = findAvailable('SEKO Holland', locations)
  } else if (countries.US.some(c => c == countryCode)) {
    inStock = findAvailable('SEKO Chicago', locations)
  }

  // Always fallback to UK for stock (all locations)
  if (!inStock) {
    inStock = findAvailable('SEKO UK', locations)
  }

  return inStock
}

/*
 * Check Variation Stock
 *
 * This function gets the locations from the passed variant, before
 * running "checkLocationStock". This differs slightly due to the fact
 * it uses the "defaultStock" as a fallback. The reason for this is so
 * we don't have variations start as all crossed out, then remove the
 * styling, it looks like bad UI.
 *
 * @ /hooks/product/useSelected
 * @ /hooks/product/useVariations
 */

export const getVariationStock = (variant, countryCode) => {
  if (!variant || !countryCode) return false

  // get list of inventory locations
  const locations = variant?.storeAvailability?.edges.map(o => o.node)

  // default stock state
  const defaultStock = variant?.availableForSale

  // Set to default state, then overwrite with inventory location..
  // This is to avoid it all flashing as crossed out on load
  const inStock = locations
    ? checkLocationStock(locations, countryCode)
    : defaultStock

  return inStock
}
