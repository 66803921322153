import styles from './cookie-banner.module'
import Button from '@/components/common/button'
import Link from '@/components/common/link'
import cx from 'clsx'
import useGdrc from '@/hooks/useGdrc'
import useAnimate from '@/hooks/useAnimate'
import useForm from '@/hooks/useForm'

const Toggle = ({ disabled, checked, onChange }) => (
  <input
    type="checkbox"
    disabled={disabled}
    checked={checked}
    onChange={onChange}
  />
)

const CookieBanner = () => {
  const [show, flags, callback] = useGdrc()
  const [state, set] = useForm(flags)
  const toggle = key => () =>
    set(key)(state[key] == 'granted' ? 'denied' : 'granted')
  const { entering, entered, left } = useAnimate(show)

  if (left) return null

  const confirm = () => callback(state)

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.root, {
          [styles.animate]: entering || entered
        })}
      >
        <h3>COOKIES</h3>

        <div className={styles.content}>
          We use cookies to provide you with the best possible experience. Our
          cookies are used to manage your basket items, keep you logged in, and
          to analyse our traffic.{' '}
          <Link href="/privacy#cookies">Learn More</Link>
        </div>

        <div className={styles.options}>
          <label className={styles.option}>
            <div className={styles.title}>NECESSARY</div>
            <Toggle disabled checked={true} />
          </label>

          <label className={styles.option}>
            <div className={styles.title}>ANALYTICS</div>
            <Toggle
              checked={state.analytics_storage == 'granted'}
              onChange={toggle('analytics_storage')}
            />
          </label>
        </div>

        <div className={styles.button}>
          <Button lightHover onClick={confirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner
