import { useState } from 'react'
import { useEffect } from 'react'

/**
 * Useful for transitions
 */
const useAnimate = (state, timeout = 300) => {
  const [nextTick, setNextTick] = useState(false)
  const [delayedState, setDelayedState] = useState(false)
  useEffect(() => {
    setTimeout(() => setNextTick(state), 0)
    setTimeout(() => setDelayedState(state), timeout)
  }, [state])

  const entering = state && nextTick && !delayedState
  const entered = state && delayedState
  const leaving = !state && delayedState
  const left = !state && !delayedState

  return { entering, leaving, entered, left }
}

export default useAnimate
