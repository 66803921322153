const SizeGuideStrapbackCap = () => {
  return (
    <>
      <h3>STRAPBACK CAP SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Age</th>
              <th>Circumference (cm)</th>
              <th>Circumference (in)</th>
              <th>Cap Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2">Infant (0-2 yrs)</td>
              <td>48.2</td>
              <td>19</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>49.2</td>
              <td>19 3/8</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="2">Toddler (2-4 yrs)</td>
              <td>50.1</td>
              <td>19 3/4</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>51.1</td>
              <td>20 1/8</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="2">Child (4-6 yrs)</td>
              <td>52</td>
              <td>20 1/2</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>53</td>
              <td>20 7/8</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="2">Youth (6-12 yrs)</td>
              <td>53.9</td>
              <td>21 1/4</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>54.9</td>
              <td>21 5/8</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="7">Adult</td>
              <td>55.8</td>
              <td>22</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>56.8</td>
              <td>22 3/8</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>57.7</td>
              <td>22 3/4</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>58.7</td>
              <td>23 1/8</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>59.6</td>
              <td>23 1/2</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>60.6</td>
              <td>23 7/8</td>
              <td>One Size</td>
            </tr>
            <tr>
              <td>61.5</td>
              <td>54 1/4</td>
              <td>One Size</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideStrapbackCap.type = 'strapback-cap'

export default SizeGuideStrapbackCap
