import { useDispatch } from 'react-redux'
import { forgot } from '@/lib/store/slice/auth'
import useStatus from '../storefront/useStatus'
import notify from '@/lib/notify'
import { closeAll } from '@/lib/store/slice/nav'

const useForgot = () => {
  const dispatch = useDispatch()
  const [inProgress, error] = useStatus('auth/forgot')

  // reset function
  const action = params => {
    dispatch(forgot(params))
    dispatch(closeAll())
    notify({
      text: 'Password reset email sent. If you do not receive an email you will need to create a new account or contact support'
    })
  }

  return [error, inProgress, action]
}

export default useForgot
