import Link from '@/components/common/link'
import Quantity from '@/components/common/quantity'
import Labels from '@/components/product/labels'
import Icon from '@/components/common/icon'
import asPrice from '@/lib/price'
import styles from './items.module'
import cx from 'clsx'
import Button from '@/components/common/button/button'
import { useState } from 'react'
import { GiftModal } from '@/components/product/single/includes/gift-modal'
import { useSelector } from 'react-redux'

export default function Item({ index, item, setQuantity, removeItem }) {
  const _gifts = useSelector(state => state.summary.gifts)
  const [itemModal, setItemModal] = useState(false)
  const [giftItem, setGiftItem] = useState(item)
  let price = item.price
  let compareAtPrice = item.compareAtPrice
  const [giftModalTriggered, setGiftModalTriggered] = useState(false)
  let disableQty = false

  let setGiftModalTrigger = decision => {
    setGiftModalTriggered(decision)
  }

  let hasGiftItem = item => {
    if (_gifts.length) {
      return _gifts[0].from.handle == item.handle ? true : false
    } else {
      return false
    }
  }

  let discountsExist = item => {
    return item?.discounts?.length ? !!item?.discounts?.length : false
  }

  let shouldQtySelectorBeShown = item => {
    if(fullyDiscountedGiftExists(item)) {
      disableQty = true
      return false
    }
    else if(fullyDiscountedExists(item)) {
      disableQty = true
      return true
    }
    if(mclarenPlusDiscountExists(item)) {
      return true
    }
    if(giftDiscountsExist(item) == false) {
      return true
    }
  }

  let giftDiscountsExist = item => {
    let existingGiftDiscount = item.discounts?.filter(discount => {
      discount.type == "gift"
    })
    if(existingGiftDiscount != null) {
      return existingGiftDiscount.length > 0 ? true : false
    } else {
      return false
    }
  }

  let fullyDiscountedExists = item => {
    let fullyDiscount = item.discounts?.filter(discount => {
      return discount.discountType == 1 && discount.value == 100
    })

    if(fullyDiscount != null) {
      return fullyDiscount.length > 0 ? true : false
    } else {
      return false
    }

  }

  let fullyDiscountedGiftExists = item => {
    let fullyDiscount = item.discounts?.filter(discount => {
      return discount.type == "gift"
    })
    if(fullyDiscount != null) {
      return fullyDiscount.length > 0 ? true : false
    } else {
      return false
    }

  }

  let mclarenPlusDiscountExists = item => {
    let existingDiscount = item.discounts?.filter(discount => {
      discount.type == "group"
    })
    if(existingDiscount) {
      return existingDiscount.length > 0 ? true : false
    } else {
      return false
    }
  }

  const isGiftItem = item => !!item.gift

  const hasGiftLabel = item => item.labels[0] == 'your free gift'

  const openGiftModal = item => {
    setItemModal(true)
    setGiftItem(item)
  }

  const discounts =
    item?.discounts?.reduce((a, b) => a + b.amountSingle, 0) || 0

  if (discounts) {
    compareAtPrice = compareAtPrice || item.price
    price = price - discounts
  }

  return (
    <div
      className={styles.item}
      data-ge-basket-cartitemid={index + 1}
      data-ge-basket-cartitemsku={item.sku}
      data-ge-quantity={item.quantity}
    >
      <div className="row">
        <div className="col-12 col-md-6 mb-2 mb-md-0">
          <div className={styles.itemImage}>
            <Labels product={item} labels={item.labels} basket={true} />
            {shouldQtySelectorBeShown(item) && (
              <div className={styles.quantity}>
              <Quantity
                quantity={item.quantity}
                setQuantity={setQuantity(index)}
                small={true}
                disabled={disableQty}
              />
            </div>
            )}
            <img
              src={item.image.split('.jpg').join('_x550.jpg')}
              alt={item.title}
            />
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex flex-column py-2">
          <Link
            href={`/products/${item.handle}`}
            className={styles.productTitle}
          >
            <h5 className="mb-2 text-uppercase">{item.title}</h5>
          </Link>
          <p className={cx('h4 text-bold', styles.price)}>
            {discountsExist(item) && isGiftItem(item) ? (
              <>
                <ins>
                  <span data-ge-basket-productsaleprice>FREE GIFT</span>
                </ins>
              </>
            ) : compareAtPrice ? (
              <>
                <ins>
                  <span data-ge-basket-productsaleprice>{asPrice(price)}</span>
                </ins>
                <del>
                  <span data-ge-basket-productlistprice>
                    {asPrice(compareAtPrice)}
                  </span>
                </del>
              </>
            ) : (
              <span data-ge-basket-productlistprice>{asPrice(price)}</span>
            )}
          </p>

          {/* TODO
           - where do we display this?
           - is it logical to take the discount off the total? is that misleading for normal offers?
           - add data tags to indicate discounts to GE
           - add a key to the loop
          */}
          {item.discounts && !isGiftItem(item) && (
            <div className="p-sm mb-2">
              {item.discounts.map((discount, i) => (
                <div key={i}>
                  <Icon icon="Tag" />
                  <span className="ml-1">
                    {discount.title}:{' '}
                    <span
                      data-ge-basket-discount-value
                      data-ge-basket-discount-amount={discount.amountSingle}
                      data-ge-basket-discount-title={discount.title}
                      data-ge-basket-discount-description={discount.description}
                      data-ge-basket-discount-type={discount.discountType}
                    >
                      {asPrice(discount.amountSingle)}
                    </span>{' '}
                    off
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className={styles.productVariant}>
            {item.options.map(({ name, value }) => (
              <div key={name}>
                <p className="mb-0 color-dark-grey">
                  <span className="text-capitalize">
                    {name == 'Color' ? 'Colour' : name}
                  </span>
                  : {value}
                </p>
              </div>
            ))}
          </div>

          {
            <div className="mb-3">
              <p className="mb-0 color-dark-grey">
                Total:&nbsp;
                <span data-ge-basket-producttotalprice>
                  {asPrice(price * item.quantity)}
                </span>
              </p>
            </div>
          }

          {hasGiftItem(item) && (
            <div className="mb-3">
              <Button
                thin
                expand
                outline
                onClick={() => openGiftModal(_gifts[0])}
              >
                Claim your free gift
              </Button>
            </div>
          )}

          {hasGiftItem(item) && (
            <GiftModal
              open={itemModal}
              setModal={setItemModal}
              product={giftItem}
            />
          )}

          <button
            className="btn-clear text-uppercase p-0 p-sm color-mid-grey d-flex align-items-center mt-auto"
            onClick={removeItem(index)}
          >
            <Icon icon="Trash" />
            <span className={cx('ml-2 text-bold', styles.remove)}>Remove</span>
          </button>
        </div>
      </div>
    </div>
  )
}
