import 'styles/global/index.scss'
import DefaultLayout from '@/layouts/default'
import Head from 'next/head'
import { store } from '@/lib/store'
import { Provider } from 'react-redux'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { pageView } from '@/lib/gtm'

const { publicRuntimeConfig } = getConfig()

function App({ Component, pageProps, layoutProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = url => pageView(url)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])

  const PageLayout = Component.Layout || DefaultLayout

  const allPageProps = {
    global: layoutProps?.global,
    ...pageProps
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,shrink-to-fit=no,user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
      </Head>

      <Provider store={store}>
        <PageLayout {...layoutProps}>
          <Component {...allPageProps} />
        </PageLayout>
      </Provider>
    </>
  )
}

App.getInitialProps = async context => {
  const common = await fetch(`${publicRuntimeConfig.api}/common`)
  const layoutProps = await common.json()

  return {
    layoutProps
  }
}

export default App
