import { saveState } from './localStorage'
import throttle from 'lodash.throttle'
import { update } from './slice/summary'
import { geCartSelector } from './slice/cart'

import { fromItems } from '@/lib/magic-link'
import newsletter from '@/lib/api/newsletter'
import notifier from './middleware/notifier'
import { closeAll } from './slice/nav'
import { login } from './slice/auth'

export const localStorageSelector = state => {
  return {
    auth: state.auth,
    cart: state.cart,
    intl: state.intl
  }
}

const saveData = throttle(state => {
  const magicLink = fromItems(state.cart.items)
  localStorage.setItem('cart-link', magicLink)

  const savedState = localStorageSelector(state)
  saveState(savedState)
}, 1000)

const middleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action)
    // @TODO investigate this
    if (typeof window == 'undefined') return

    if (/^auth\/register\/fulfilled/.test(action.type)) {
      dispatch(closeAll())
      const loginParams = {
        email: action.meta.arg.input.email,
        password: action.meta.arg.input.password,
        remember: false
      }
      dispatch(login(loginParams))
    }

    notifier(action)

    const state = getState()

    if (
      (/^cart/.test(action.type) &&
        !/pending$/.test(action.type) &&
        !/rejected$/.test(action.type)) ||
      action.type == 'auth/logout' ||
      action.type == 'customer/profile/update/fulfilled' ||
      action.type == 'customer/fetch/fulfilled' ||
      action.type == 'auth/reset/fulfilled' ||
      action.type == 'auth/activate/fulfilled'
    ) {
      dispatch(update())
    }

    if (/^summary/.test(action.type) && typeof window !== 'undefined') {
      const cartData = geCartSelector(state)
      window?.GEM_Components?.ExternalMethodsComponent?.UpdateCart(cartData)
    }

    // @TODO send the correct props so this works
    // if (action.type === 'auth/register/fulfilled' && action.payload.customer) {
    //   newsletter(action.payload.customer)
    // }

    if (action.type !== 'INIT') {
      saveData(state)
    }
  }

export default middleware
