const SizeGuideStretchFitCap = () => {
  return (
    <>
      <h3>STRETCHFIT CAP SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Age</th>
              <th>Circumference (cm)</th>
              <th>Cap Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4">Toddler / Child (0-4 yrs)</td>
              <td>48.2</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>49.2</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>50.1</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>51.1</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="3">Child / Youth (4-12 yrs)</td>
              <td>52</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>53</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>53.9</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td rowSpan="9">Adult</td>
              <td>54.9</td>
              <td>XS/S</td>
            </tr>
            <tr>
              <td>56.3</td>
              <td>S/M</td>
            </tr>
            <tr>
              <td>57.7</td>
              <td>S/M</td>
            </tr>
            <tr>
              <td>58.7</td>
              <td>S/M</td>
            </tr>
            <tr>
              <td>59.6</td>
              <td>M/L</td>
            </tr>
            <tr>
              <td>60.6</td>
              <td>M/L</td>
            </tr>
            <tr>
              <td>61.5</td>
              <td>L/XL</td>
            </tr>
            <tr>
              <td>62.5</td>
              <td>L/XL</td>
            </tr>
            <tr>
              <td>63.5</td>
              <td>L/XL</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideStretchFitCap.type = 'stretch-fit-cap'

export default SizeGuideStretchFitCap
