import Icon from '@/components/common/icon'
import styles from './container.module.scss'

export const Container = ({ setOpen, children }) => {
  return (
    <div className="d-flex flex-column">
      <div className={styles.header}>
        <h5 className="mb-0 text-uppercase">Basket</h5>
        <button
          className="btn-clear d-flex align-items-center color-black"
          onClick={() => setOpen(false)}
        >
          <Icon icon="Times" size="lg" />
          <span className="text-uppercase pl-2">Close</span>
        </button>
      </div>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
