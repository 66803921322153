import cx from 'clsx'
import styles from './color.module.scss'

const Color = ({ color, disabled, available, selected, small, onClick }) => {
  let color1 = 'product-color-white'
  let color2 = 'product-color-white'

  if (color) {
    const colors = color
      .split('/')
      .map(o => o.trim().toLowerCase().replace(/ /, '-'))
    color1 = `product-color-${colors[0]}`
    color2 = `product-color-${colors[1]}`
  }

  const className = cx(styles.root, {
    [styles.selected]: selected,
    [styles.disabled]: disabled,
    [styles.notAvailable]: available,
    [styles.small]: small
  })

  const clickHandler = () => onClick && onClick()

  return (
    <div className={className} title={color ? color : ''}>
      <div className={cx(styles.outer, color1)} onClick={clickHandler}>
        <div className={cx(styles.inner, color2)} />
      </div>
    </div>
  )
}

export default Color
