import gql from './client'

// https://shopify.dev/api/storefront/reference/products/product
const query = gql`
  query {
    localization {
      availableCountries {
        name
        isoCode
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async res => {
  return res.localization.availableCountries
}

export default query(responseHandler)
