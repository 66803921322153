export default function availableOptions(variants, option) {
  if (!variants) return null

  // Get available options from variants available
  let available = variants.map(e => {
    const color = e?.selectedOptions.find(c => c?.name == option)
    return color ? color?.value : false
  })

  if (!available) return null

  // Remove duplicates
  available = [...new Set(available)]

  return available
}
