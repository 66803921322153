import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import useNav from '@/hooks/useNav'
import styles from '../styles/button-flag.module'

export const ButtonFlag = () => {
  const [toggleModal] = useNav('country')

  const [flag, setFlag] = useState(
    `https://intgepi.bglobale.com/content/images/flags/gb.png`
  )

  const country = useSelector(state => state.intl.country).toLowerCase()

  useEffect(() => {
    if (!country) return
    setFlag(`https://intgepi.bglobale.com/content/images/flags/${country}.png`)
  }, [country])

  return (
    <div className={styles.root}>
      <img alt={country} onClick={toggleModal} src={flag} />
    </div>
  )
}
