import styles from '../styles/burger.module.scss'

export const ButtonBurger = ({ open, setOpen }) => {
  return (
    <button
      open={open}
      onClick={() => setOpen(!open)}
      className={styles.burger}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}
