import FormForgot from '@/components/forms/forgot-password'

export const ForgotForm = ({ setRegister, setForgot }) => {
  return (
    <>
      <p className="p-sm">
        If you can&#39;t remember your password, you can reset it
      </p>
      <FormForgot modal={true} />
      <div className="text-center pt-3">
        <p className="p-sm">
          <button
            className="btn-clear text-underline p-sm p-0 color-black"
            onClick={() => {
              setForgot(false)
              setRegister(false)
            }}
          >
            log in to your account
          </button>
        </p>
      </div>
    </>
  )
}
