import gql from './client'

// example input
const variables = {
  input: {
    // acceptsMarketing: true,
    email: 'user@example.com',
    firstName: 'optional',
    lastName: 'optional',
    password: 'HiZqFuDvDdQ7',
    phone: 'optionalString'
  }
}

// https://shopify.dev/api/storefront/reference/customers/customeraddresscreate
const query = gql`
  mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        code
        field
        message
      }
      deletedCustomerAddressId
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerAddressDelete }) => {
  const { customerUserErrors } = customerAddressDelete
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
}

export default query(responseHandler)
