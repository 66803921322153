import gql from './client'

// example input
const variables = {
  email: 'user@example.com'
}

// https://shopify.dev/api/storefront/reference/customers/customerrecover
const query = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerRecover }) => {
  const { customerUserErrors } = customerRecover
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
}

export default query(responseHandler)
