import cx from 'clsx'
import Link from '@/components/common/link'
import Icon from '@/components/common/icon'
import styles from '../styles/navigation.module.scss'

export const Navigation = ({ items }) => (
  <ul className={styles.navigation}>
    {items.map((item, i) => (
      <NavigationItem item={item} key={i} />
    ))}
  </ul>
)

const NavigationItem = ({ item }) => (
  <li className={styles.navigationItem}>
    <Link href={item.url + "?shown=24&sort=featured"}>{item.title}</Link>
    {item.items && (
      <ul className={styles.navigationDropdown}>
        {item.items.map((group, i) => (
          <ChildItem item={group} key={i} />
        ))}
      </ul>
    )}
  </li>
)

const ChildItem = ({ item }) => {
  if (!item.items?.length) {
    return (
      <li className={styles.subNavigationItem}>
        <Link href={item.url + "?shown=24&sort=featured"}>
          <span>{item.title}</span>
          <span className={cx(styles.arrow, 'navigation-arrow')}>
            <Icon icon="ArrowRight" />
          </span>
        </Link>
      </li>
    )
  }

  return (
    <li className={styles.navigationGroup}>
      <Link href={item.url + "?shown=24&sort=featured"}>{item.title}</Link>

      <ul className={styles.subNavigation}>
        {item.items.map((child, i) => (
          <ChildItem item={child} key={i} />
        ))}
      </ul>
    </li>
  )
}
