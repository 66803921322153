import useBasket from '@/hooks/useBasket'
// import usePreOrder from '@/hooks/product/usePreOrder'
import styles from './items.module'
import Item from './item'

export default function Items() {
  const { items, count, setQuantity, removeItem } = useBasket()

  return (
    <div className={styles.root}>
      {/* {gift && ( */}
      {/*   <GiftModal open={giftModal} setModal={setGiftModal} product={gift} /> */}
      {/* )} */}
      <p className="p-xl text-medium">Total items: {count}</p>
      {items.map((item, i) => (
        <Item
          key={i}
          index={i}
          item={item}
          setQuantity={setQuantity}
          removeItem={removeItem}
        />
      ))}
    </div>
  )
}
