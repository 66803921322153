import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  inProgress: {},
  fulfilled: {},
  errors: []
}

// slice
export const storefrontSlice = createSlice({
  name: 'storefront',
  initialState,
  extraReducers: builder =>
    builder
      .addMatcher(
        action => action.type.endsWith('pending'),
        (state, action) => {
          const type = action.type.slice(0, -8)
          state.inProgress[type] = true
          state.fulfilled[type] = false
        }
      )
      .addMatcher(
        action => action.type.endsWith('rejected'),
        (state, action) => {
          const error = action.payload ?? action.error

          const type = action.type.slice(0, -9)
          state.inProgress[type] = false
          state.errors.push({
            ...error,
            t: Date.now(),
            type,
            meta: action.meta
          })
        }
      )
      .addMatcher(
        action => action.type.endsWith('fulfilled'),
        (state, action) => {
          const type = action.type.slice(0, -10)
          state.inProgress[type] = false
          state.fulfilled[type] = Date.now()
        }
      )
})

// selectors
export const statusSelector = state => {
  const { inProgress, errors, fulfilled } = state.storefront
  return { inProgress, errors, fulfilled }
}

export default storefrontSlice.reducer
