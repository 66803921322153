import packageJson from '../../package.json'

// stay logged in for 30 days
const maxAge = 1000 * 60 * 60 * 24 * 30

/**
 * If the major/minor version differs, the cache will be cleared.
 * This avoids horrible problems that occur when the state structure changes
 *
 */
const compareVersions = last => {
  const v1 = last.split('.').slice(0, 2).join('.')
  const v2 = packageJson.version.split('.').slice(0, 2).join('.')
  return v1 !== v2
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('mclaren-state', serializedState)
    localStorage.setItem('mclaren-version', packageJson.version)
    localStorage.setItem('mclaren-date', Date.now())

    if (process.env.NODE_ENV == 'development') {
      window.state = state
    }
  } catch {
    // We'll just ignore write errors
  }
}

export const loadState = () => {
  try {
    const lastVersion = localStorage.getItem('mclaren-version')
    if (!lastVersion || compareVersions(lastVersion)) {
      return undefined
    }

    const age = localStorage.getItem('mclaren-date')
    if (Date.now() - age > maxAge) {
      return undefined
    }

    const serializedState = localStorage.getItem('mclaren-state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    return undefined
  }
}
