import { useState } from 'react'
import {
  addVoucher as _addVoucher,
  removeVoucher as _removeVoucher
} from '@/lib/store/slice/cart'
import { useDispatch } from 'react-redux'
import voucherRequest from '@/lib/api/voucher'

const useVoucher = () => {
  const dispatch = useDispatch()
  const [voucherError, setVoucherError] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const addVoucher = (code, items, email = '') => {
    setInProgress(true)
    setVoucherError(false)

    voucherRequest({ code, items, email })
      .then(() => {
        dispatch(_addVoucher(code))

        setTimeout(() => {
          setInProgress(false)
        }, 500)
      })
      .catch(error => {
        setVoucherError(error?.message ? error.message : error)

        setTimeout(() => {
          setInProgress(false)
        }, 2000)

        setTimeout(() => {
          setVoucherError(false)
        }, 10000)
      })
  }

  const removeVoucher = code => () => dispatch(_removeVoucher({ code }))

  return { addVoucher, removeVoucher, inProgress, voucherError }
}

export default useVoucher
