import Banner from '@/components/global/plus-banner'
import FormLogin from '@/components/forms/login'

export const LoginForm = props => {
  return (
    <>
      <div className="text-center mb-5">
        {props?.global?.login?.modal && (
          <div
            dangerouslySetInnerHTML={{
              __html: props.global.login.modal
            }}
          />
        )}
      </div>
      <FormLogin modal={true} />
      <div className="text-center pt-3">
        <p className="p-sm">
          <button
            className="btn-clear text-underline p-sm p-0 color-black"
            onClick={() => {
              props.setForgot(true)
              props.setRegister(false)
            }}
          >
            Forgotten password?
          </button>
        </p>
        <p className="p-lg">Dont have an account yet?</p>
        <p className="p-sm mb-0">
          <button
            className="btn-clear text-underline p-sm p-0 color-black"
            onClick={() => props.setRegister(true)}
          >
            Register now
          </button>
        </p>
        <Banner
          title={props?.global?.sitewide?.mclarenPlusText}
          image={props?.global?.sitewide?.mclarenPlusImage}
        />
      </div>
    </>
  )
}
