import cx from 'clsx'
import styles from './category-accordion.module.scss'
import { CategoryItem } from './includes/category-item'
import { useState, useEffect } from 'react'

// NOTE: Look at converting this to use the common 'accordion'

const CategoryAccordion = props => {
  const [clicked, setClicked] = useState(null)
  const [imageShown, setIsShown] = useState({ src: '', alt: '' })

  const handleToggle = index => {
    if (clicked === index) {
      return setClicked('0')
    }
    setClicked(index)
  }

  useEffect(() => {
    if (props.imagePreviews && props.items) {
      const defaultImage = props.items[0].image
      if (defaultImage) {
        setIsShown({
          src: defaultImage.src ? defaultImage.src : '',
          alt: defaultImage.alt ? defaultImage.alt : ''
        })
      }
    }

    if (props.openOnDesktop) {
      setClicked(0)
    }
  }, [])

  return (
    <div
      className={cx(styles.root, {
        container: props.container
      })}
    >
      {props.title && <h3 className={styles.title}>{props.title}</h3>}
      {props.imagePreviews ? (
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5">
            <ul className={styles.list}>
              {props.items.map((item, index) => (
                <CategoryItem
                  onToggle={() => handleToggle(index)}
                  onMouseEnter={setIsShown}
                  active={clicked === index}
                  item={item}
                  itemKey={index}
                  key={index}
                />
              ))}
            </ul>
          </div>
          <div className="d-none d-lg-block col-lg-6 offset-xl-1">
            {imageShown.src && imageShown.alt && (
              <img
                className="img-fluid w-100"
                src={imageShown.src}
                alt={imageShown.alt}
              />
            )}
          </div>
        </div>
      ) : (
        <ul className={styles.list}>
          {props.items.map((item, index) => (
            <CategoryItem
              onToggle={() => handleToggle(index)}
              active={clicked === index}
              item={item}
              key={index}
            />
          ))}
        </ul>
      )}
    </div>
  )
}

export default CategoryAccordion
