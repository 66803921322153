import cx from 'clsx'
import Link from '@/components/common/link'
import Icon from '@/components/common/icon'
import { useEffect, useState } from 'react'
import { LoginForm } from './includes/login'
import { RegisterForm } from './includes/register'
import { ForgotForm } from './includes/forgot-form'
import { Navigation } from './includes/navigation'
import { ButtonBurger } from './includes/button-burger'
import { ButtonLogin } from './includes/button-login'
import { ButtonBasket } from './includes/button-basket'
import { ButtonFlag } from './includes/button-flag'
import { Search } from './includes/search'
import { SearchMobile } from './includes/search-mobile'
import CategoryAccordion from '@/blocks/category-accordion'
import OffCanvas from '@/components/common/off-canvas'
import Modal from '@/components/common/modal'
import Logo from '@/components/common/logo'
import LinkList from '@/components/common/link-list'
import styles from './header.module.scss'
import useNav from '@/hooks/useNav'

const Header = props => {
  // Offcanvas / Modal elements
  const [setOffCanvasOpen, isOffCanvasOpen] = useNav('menu')
  const [setLoginModalOpen, isLoginModalOpen] = useNav('login')
  const [setSearchModalOpen, isSearchModalOpen] = useNav('search')

  // Which form is shown on the login modal
  const [registerActive, setRegister] = useState(false)
  const [forgotActive, setForgot] = useState(false)

  // conditionally display our country selector
  const [tdrModal, setTdrModal] = useState(false)

  // TDR Currency Selector modal
  useEffect(() => {
    setTdrModal(window.location.search.includes('tdr-modal'))
  }, [])

  const registerProps = {
    setRegister: setRegister,
    global: props?.global
  }

  const loginProps = {
    setRegister: setRegister,
    setForgot: setForgot,
    global: props?.global
  }

  return (
    <>
      <Modal logo open={isLoginModalOpen} setOpen={setLoginModalOpen}>
        {forgotActive ? (
          <ForgotForm setRegister={setRegister} setForgot={setForgot} />
        ) : registerActive ? (
          <RegisterForm {...registerProps} />
        ) : (
          <LoginForm {...loginProps} />
        )}
      </Modal>
      <OffCanvas
        open={isOffCanvasOpen}
        setOpen={setOffCanvasOpen}
        className={styles.offcanvas}
      >
        <CategoryAccordion items={props?.header?.nav} />
        <hr className="border-black my-3" />
        <LinkList
          items={props?.header?.navOffcanvasSecondary}
          listClass={styles.navLinks}
        />
      </OffCanvas>
      <OffCanvas
        open={isSearchModalOpen}
        setOpen={setSearchModalOpen}
        className={styles.searchOffcanvas}
      >
        <SearchMobile />
      </OffCanvas>
      <div className={styles.root}>
        <div className={styles.headerTop}>
          <div
            className={cx(
              styles.headerTopInner,
              'container-fluid d-flex justify-content-between py-1'
            )}
          >
            <div className="d-none d-md-block"></div>
            <div className="d-flex align-items-center justify-content-md-center">
              {props?.header?.siteMessage && (
                <p
                  className={cx(
                    'text-bold text-uppercase mb-0',
                    styles.siteMessage
                  )}
                >
                  <span data-ge-sitemessage>{props?.header?.siteMessage}</span>
                </p>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <div>
                {tdrModal == true ? (
                  <ButtonFlag />
                ) : (
                  <div data-ge-shippingswitcher-flag />
                )}
              </div>
              <div>
                <ButtonLogin setModal={setLoginModalOpen} label={true} />
              </div>
              <div>
                <ButtonBasket />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.headerMain}>
          <div className="container-fluid d-flex justify-content-between">
            <div className={styles.navigationWrap}>
              <div className="align-self-center mr-4 mr-sm-2 mr-lg-6">
                <ButtonBurger
                  open={isOffCanvasOpen}
                  setOpen={setOffCanvasOpen}
                />
              </div>
              <button
                className="btn-clear color-white p-1 align-self-center d-block d-sm-none"
                onClick={() => setSearchModalOpen(!isSearchModalOpen)}
              >
                <Icon icon="Search" size="lg" />
              </button>
              <Navigation items={props?.header?.nav} />
              <div className="d-none d-sm-block flex-fill">
                <Search />
              </div>
            </div>
            <div className={styles.logoWrap}>
              <div className={styles.logo}>
                <Link href="/">
                  <Logo />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
