import gql from './client'
import newsletter from '@/lib/api/newsletter'

// example input
const variables = {
  input: {
    // acceptsMarketing: true,
    email: 'user@example.com',
    firstName: 'optional',
    lastName: 'optional',
    password: 'HiZqFuDvDdQ7',
    phone: 'optionalString',
    acceptsMarketing: true
  }
}

// https://shopify.dev/api/storefront/reference/customers/customercreate
const query = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
        firstName
        lastName
        email
        acceptsMarketing
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerCreate }) => {
  const { customer, customerUserErrors } = customerCreate
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }

  newsletter(customer)
}

export default query(responseHandler)
