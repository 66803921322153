import { useState } from 'react'
import useRegister from '@/hooks/auth/useRegister'
import Link from '@/components/common/link'
import Button from '@/components/common/button/button'
import Input from '@/components/common/input/input'
import FormError from '@/components/common/form-error'

const FormRegister = props => {
  const [error, inProgress, register] = useRegister()

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [acceptsMarketing, setAcceptsMarketing] = useState(false)

  const onClick = () => {
    register({ email, firstName, lastName, password, acceptsMarketing })
  }

  const cols = props?.modal ? 'col-12 mb-3' : 'col-12 col-lg-6 mb-3'

  return (
    <form>
      <div className="row row-sm">
        <div className={cols}>
          <Input
            placeholder="First Name"
            value={firstName}
            onChange={setFirstName}
          />
        </div>
        <div className={cols}>
          <Input
            placeholder="Last Name"
            value={lastName}
            onChange={setLastName}
          />
        </div>
      </div>
      <div className="row row-sm">
        <div className={cols}>
          <Input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={setEmail}
          />
        </div>
        <div className={cols}>
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={setPassword}
          />
        </div>
      </div>
      <div className="mb-3">
        <Input
          type="checkbox"
          label={props?.checkbox}
          value={acceptsMarketing}
          onChange={setAcceptsMarketing}
        />
      </div>
      <div className="row row-sm">
        <div className={cols}>
          <Button className="w-100" onClick={onClick} disabled={inProgress}>
            Create account
          </Button>
        </div>
      </div>
      {!props?.modal && (
        <p className="p-sm mb-1">
          Already have an account?{' '}
          <Link href="/account/login">
            <span className="text-underline">Login here</span>
          </Link>
        </p>
      )}
      {error && <FormError error={error} />}
    </form>
  )
}

export default FormRegister
