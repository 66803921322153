import { useDispatch } from 'react-redux'
import { login } from '@/lib/store/slice/auth'
import useStatus from '../storefront/useStatus'

const useLogin = () => {
  const dispatch = useDispatch()
  const [inProgress, error] = useStatus('auth/login')

  // log in function
  const action = ({ email, password, remember }) => {
    dispatch(login({ email, password, remember }))
  }

  return [error, inProgress, action]
}

export default useLogin
