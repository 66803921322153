import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  country: false,
  login: false,
  menu: false,
  basket: false
}

// slice
export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    toggle: (state, action) => {
      const { item } = action.payload
      state[item] = !state[item]
    },
    closeAll: state => {
      state.country = false
      state.login = false
      state.menu = false
      state.basket = false
    }
  }
})

// selectors
export const navSelector = state => state.nav

export const { toggle, closeAll } = navSlice.actions
export default navSlice.reducer
