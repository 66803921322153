import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const newsletter = ({ firstName, lastName, email, acceptsMarketing }) => {
  if (acceptsMarketing) {
    fetch(`${publicRuntimeConfig.api}/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email
      })
    })
  }
}

export default newsletter
