import { useDispatch } from 'react-redux'
import { register } from '@/lib/store/slice/auth'
import useStatus from '../storefront/useStatus'

const useRegister = () => {
  const dispatch = useDispatch()
  const [inProgress, error] = useStatus('auth/register')

  // register function
  const action = params => {
    dispatch(register({ input: { ...params } }))
  }

  return [error, inProgress, action]
}

export default useRegister
