export default [
  'AI',
  'AG',
  'AR',
  'BS',
  'BB',
  'BZ',
  'BM',
  'BO',
  'BQ',
  'BR',
  'VG',
  'CA',
  'KY',
  'CL',
  'CO',
  'CR',
  'CW',
  'DM',
  'DO',
  'EC',
  'SV',
  'FK',
  'GF',
  'GL',
  'GD',
  'GP',
  'GT',
  'GY',
  'HT',
  'HN',
  'JM',
  'MQ',
  'MX',
  'MS',
  'AN',
  'NI',
  'PA',
  'PY',
  'PE',
  'PR',
  'KN',
  'LC',
  'VC',
  'SX',
  'BL',
  'MF',
  'SR',
  'TT',
  'TC',
  'US',
  'UY',
  'VE'
]
