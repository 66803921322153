import getConfig from 'next/config'
import notify from '../notify'

const { publicRuntimeConfig } = getConfig()

const basketRequest = cart =>
  fetch(`${publicRuntimeConfig.api}/basket`, {
    method: 'POST',
    body: JSON.stringify(cart),
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => notifyIfVoucherRemoved(res, cart))

const notifyIfVoucherRemoved = (res, cart) => {
  let attemptedVouchers = cart.vouchers
  let actualVouchers = res.vouchers
  let voucherName = attemptedVouchers[0]

  if (actualVouchers.length < attemptedVouchers.length) {
    notify({
      text: `Voucher "${voucherName}" is not applicable to this basket`
    })
  }

  return res
}

let to

const debouncedBasketRequest = cart =>
  new Promise(res => {
    to && clearTimeout(to)
    to = setTimeout(() => {
      to = undefined
      res(basketRequest(cart))
    }, 1000)
  })

export default debouncedBasketRequest
