/*
 * Convert color to URL
 *
 * Takes a color value and makes it pretty for the URL
 *
 */
export const colorToUrl = value => {
  if (!value) return false

  let newValue = value.replace(/\//g, '_').replace(/\s+/g, '-') // replace slash and space characters

  newValue = newValue.toLowerCase() // lowercase
  newValue = encodeURIComponent(newValue) // encode for URL

  return newValue
}

/*
 * Convert URL to color
 *
 * Takes a url value and converts it back to Shopify color
 *
 */
export const urlToColor = value => {
  if (!value) return false

  let newValue = decodeURIComponent(value) // decode URL

  newValue = newValue.toUpperCase() // uppercase
  newValue = newValue.replace(/_/g, '/').replace(/-/g, ' ') // replace dash and underscore characters

  return newValue
}
