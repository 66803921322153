import { useEffect, useState } from 'react'
import isEqual from 'lodash.isequal'

const useForm = initialState => {
  const [state, setState] = useState(initialState)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  useEffect(() => {
    setChanged(!isEqual(initialState, state))
  }, [state])

  const reset = () => setState(initialState)
  const set = key => val => setState({ ...state, [key]: val })

  return [state, set, changed, reset]
}

export default useForm
