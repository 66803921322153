import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loggedInSelector } from './auth'
import customerGetNextAddresses from '../../storefront/get-next-addresses'
import customerGetNextOrders from '../../storefront/get-next-orders'
import customerUpdate from '../../storefront/customer-update'
import queryCustomer from '../../storefront/query-customer'
import customerAddressCreate from '../../storefront/customer-address-create'
import customerAddressUpdate from '../../storefront/customer-address-update'
import customerAddressDelete from '../../storefront/customer-address-delete'

export const maxAddresses = 20
export const addressesPerPage = 3
export const ordersPerPage = 5

const initialState = {
  fetched: 0,
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  acceptsMarketing: false,
  addresses: [],
  addressCursor: null,
  orders: [],
  ordersCursor: null
}

// slice
export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    'fetch/fulfilled': (state, action) => {
      state.id = action.payload.id
      state.firstName = action.payload.firstName || ''
      state.lastName = action.payload.lastName || ''
      state.email = action.payload.email || ''
      state.phone = action.payload.phone || ''
      state.acceptsMarketing = action.payload.acceptsMarketing
      state.addresses = action.payload.addresses
      state.addressCursor = action.payload.addressCursor
      state.orders = action.payload.orders
      state.ordersCursor = action.payload.ordersCursor
    },
    'profile/update/fulfilled': (state, action) => {
      state.firstName = action.payload.customer.firstName
      state.lastName = action.payload.customer.lastName
      state.email = action.payload.customer.email
      state.phone = action.payload.customer.phone
      state.acceptsMarketing = action.payload.customer.acceptsMarketing
    },
    'address/fetch/fulfilled': (state, action) => {
      state.addresses.push(...action.payload.addresses)
      state.addressCursor = action.payload.addressCursor
    },
    'address/edit/fulfilled': (state, action) => {
      const id = action.meta.arg.id
      const address = state.addresses.find(o => o.id == id)
      if (!address) return state

      for (let field in action.payload) {
        address[field] = action.payload[field]
      }
    },
    'address/delete/fulfilled': (state, action) => {
      const id = action.meta.arg.id
      const index = state.addresses.findIndex(o => o.id == id)
      if (!index) return state
      state.addresses = state.addresses.splice(index, 1)
    },
    'orders/fetch/fulfilled': (state, action) => {
      state.orders.push(...action.payload.orders)
      state.ordersCursor = action.payload.ordersCursor
    }
  },
  extraReducers: {
    'auth/logout': (state, action) => initialState
  }
})

// selectors
export const customerIdSelector = state => {
  const loggedIn = loggedInSelector(state)
  return loggedIn ? state.customer.id : ''
}

export const emailSelector = state => state.customer.email
export const marketingSelector = state => state.customer.acceptsMarketing

export const profileSelector = state => {
  return {
    firstName: state.customer.firstName,
    lastName: state.customer.lastName,
    email: state.customer.email,
    phone: state.customer.phone,
    acceptsMarketing: state.customer.acceptsMarketing
  }
}

// async

export const fetchCustomer = createAsyncThunk(
  'customer/fetch',
  async (args, { getState }) => {
    const { auth } = getState()
    const { accessToken } = auth
    return queryCustomer({
      accessToken,
      numAddresses: maxAddresses,
      numOrders: ordersPerPage
    })
  },
  {
    condition: (args, { getState }) => {
      const state = getState()
      const loggedIn = loggedInSelector(state)

      if (!loggedIn) return false

      const fetched = state.customer.fetched
      const diff = Date.now() - fetched
      if (diff < 30000) {
        return false
      }
    }
  }
)

export const updateProfile = createAsyncThunk(
  'customer/profile/update',
  async (args, { getState, rejectWithValue }) => {
    const { auth } = getState()
    return customerUpdate({
      ...args,
      customerAccessToken: auth.accessToken
    }).catch(rejectWithValue)
  }
)

export const updatePassword = createAsyncThunk(
  'customer/password/update',
  async ({ password }, { getState }) => {
    const { auth } = getState()
    return customerUpdate({
      customer: { password },
      customerAccessToken: auth.accessToken
    })
  }
)

export const fetchAddresses = createAsyncThunk(
  'customer/address/fetch',
  async (_, { getState }) => {
    const { auth, customer } = getState()
    return customerGetNextAddresses({
      accessToken: auth.accessToken,
      cursor: customer.addressCursor,
      addressesPerPage
    })
  }
)

export const addressCreate = createAsyncThunk(
  'customer/address/create',
  async (args, { getState }) => {
    const { auth } = getState()
    const { accessToken } = auth
    return customerAddressCreate({ ...args, customerAccessToken: accessToken })
  }
)

export const addressEdit = createAsyncThunk(
  'customer/address/edit',
  async (args, { getState }) => {
    const { auth } = getState()
    const { accessToken } = auth
    return customerAddressUpdate({ ...args, customerAccessToken: accessToken })
  }
)

export const addressDelete = createAsyncThunk(
  'customer/address/delete',
  async (args, { getState }) => {
    const { auth } = getState()
    const { accessToken } = auth
    return customerAddressDelete({ ...args, customerAccessToken: accessToken })
  }
)

export const fetchOrders = createAsyncThunk(
  'customer/orders/fetch',
  async (_, { getState }) => {
    const { auth, customer } = getState()
    return customerGetNextOrders({
      accessToken: auth.accessToken,
      cursor: customer.addressCursor,
      ordersPerPage
    })
  }
)

export default customerSlice.reducer
