import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  country: 'GB',
  currency: 'GBP',
  culture: 'en-GB',
  operated: false
}

// slice
export const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    fromCookie: (state, action) => {
      state.country = action.payload.countryISO
      state.currency = action.payload.currencyCode
      state.culture = action.payload.cultureCode
      // missing IsOperatedByGlobalE in cookie...
    },
    fromEvent: (state, action) => {
      state.country = action.payload.CountryCode
      state.currency = action.payload.CurrencyCode
      state.culture = action.payload.CultureCode
      state.operated = action.payload.IsOperatedByGlobalE
    },
    fromWindow: (state, action) => {
      state.country = action.payload.CountryCode
      state.currency = action.payload.CurrencyCode
      state.culture = action.payload.CultureCode
      state.operated = action.payload.IsOperatedByGlobalE
    }
  }
})

// selectors
export const countrySelector = state => state.intl.country
export const currencySelector = state => state.intl.currency
export const cultureSelector = state => state.intl.culture
export const isGlobalECheckoutSelector = state => state.intl.operated

export const { fromCookie, fromEvent, fromWindow } = intlSlice.actions
export default intlSlice.reducer
