import gql from './client'
import { getNodesAndCursor } from './query-customer'

const query = gql`
  query ($accessToken: String!, $cursor: String!, $numAddresses: Int!) {
    customer(customerAccessToken: $accessToken) {
      addresses(first: $numAddresses, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            address1
            address2
            city
            zip
            company
            country
            firstName
            lastName
            phone
          }
        }
      }
    }
  }
`

const responseHandler = async res => {
  let { addresses: a } = res.customer

  let [addresses, addressCursor] = getNodesAndCursor(a)

  return {
    addresses,
    addressCursor
  }
}

export default query(responseHandler)
