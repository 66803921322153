import { useState } from 'react'
import Link from '@/components/common/link'
import Input from '@/components/common/input'
import Button from '@/components/common/button'
import FormError from '@/components/common/form-error'
import useLogin from '@/hooks/auth/useLogin'
import useRedirect from '@/hooks/auth/useRedirect'

const FormLogin = ({ modal = false, hasMarketing = false }) => {
  useRedirect({ to: '/account', whenLoggedIn: true, and: modal })
  const [error, inProgress, login] = useLogin()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const loginAction = () => login({ email, password, remember })

  const cols = modal ? 'col-12 mb-3' : 'col-12 col-lg-6 mb-3'

  return (
    <form>
      <div className="row row-sm">
        <div className={cols}>
          <Input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={setEmail}
            onEnter={loginAction}
            autoComplete="email"
          />
        </div>
        <div className={cols}>
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={setPassword}
            onEnter={loginAction}
            autoComplete="current-password"
          />
        </div>
      </div>
      {/* <div className="mb-3">
        <Input
          type="checkbox"
          label="Remember me for next time"
          value={remember}
          onChange={setRemember}
        />
      </div> */}
      <div className="row row-sm">
        <div className={cols}>
          <Button className="w-100" onClick={loginAction} disabled={inProgress}>
            {inProgress ? 'Please Wait' : 'Login'}
          </Button>
        </div>
      </div>

      {!modal && (
        <div className="d-flex flex-column flex-sm-row align-items-sm-center">
          <p className="p-sm mb-1 mb-sm-0">
            Forgot Password?{' '}
            <Link href="/account/forgot">
              <span className="text-underline">Request a reset</span>
            </Link>
          </p>
          <div className="d-none d-sm-inline-block px-2">|</div>
          <p className="p-sm mb-1 mb-sm-0">
            Dont have an account?{' '}
            <Link href="/account/register">
              <span className="text-underline">Register now</span>
            </Link>
          </p>
        </div>
      )}
      {error && <FormError error={error} />}
    </form>
  )
}

export default FormLogin
