import notify from '@/lib/notify'

const notifier = ({ type, error }) => {
  switch (type) {
    case 'auth/login/fulfilled':
      return notify({ text: 'Logged in' })
    case 'auth/register/fulfilled':
      return notify({ text: 'Successfully Registered' })
    case 'auth/reset/fulfilled':
      return notify({ text: 'Password Reset Successfully. Logged In' })
    case 'auth/reset/rejected':
      return notify({ text: 'Password Reset Failed: ' + error.message })
    case 'auth/activate/fulfilled':
      return notify({ text: 'Account Activated Successfully. Logged In' })
    case 'auth/activate/rejected':
      return notify({ text: 'Account Activation Failed: ' + error.message })
    case 'auth/logout':
      return notify({ text: 'Logged Out' })
    case 'customer/profile/update/fulfilled':
      return notify({ text: 'Customer Profile Updated' })
    case 'customer/profile/update/rejected':
      return notify({ text: 'Customer Profile Update Failed' })
    case 'customer/password/update/fulfilled':
      return notify({ text: 'Customer Password Updated' })
    case 'customer/password/update/rejected':
      return notify({ text: 'Customer Password Update Failed' })
    case 'customer/address/create/fulfilled':
      return notify({ text: 'New Address Created' })
    case 'customer/address/edit/fulfilled':
      return notify({ text: 'Address Updated Successfully' })
    case 'customer/address/delete/fulfilled':
      return notify({ text: 'Address Deleted Successfully' })
  }
}

export default notifier
