import getCartVariants from '@/lib/storefront/get-cart-variants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import notify from '@/lib/notify'

const initialState = {
  items: [],
  vouchers: []
}

// slice
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { id, quantity, ...product } = action.payload

      let index = state.items.findIndex(
        o => o.id == id && o?.discount?.amount == product?.discount?.amount && o?.labels == product?.labels
      )
      if (index === -1) {
        index += state.items.push({ ...product, id, quantity: 0 })
      }
      state.items[index].quantity += quantity
      // cheeky side effect
      notify({ text: 'Product added to cart' })
    },
    setQuantity: (state, action) => {
      const { index, quantity } = action.payload
      if (index !== -1) {
        state.items[index].quantity = quantity
      }
    },
    removeItem: (state, action) => {
      const { index } = action.payload
      state.items.splice(index, 1)

      if (!state.items.length) {
        state.vouchers = []
      }
    },
    addVoucher: (state, action) => {
      // state.vouchers.push(action.payload)
      // only allow 1 voucher at a time
      state.vouchers = [action.payload]
    },
    removeVoucher: (state, action) => {
      const { code } = action.payload
      state.vouchers = state.vouchers.filter(o => o != code)
    },
    clearCart: (state, action) => {
      state.items = []
      state.vouchers = []
    },
    'sync/fulfilled': (state, action) => {
      state.items = action.payload
    }
  },
  extraReducers: {
    'summary/update/fulfilled': (state, action) => {
      if (action.payload.items) {
        state.items = action.payload.items
      }
      if (action.payload.vouchers) {
        state.vouchers = action.payload.vouchers
      }
    }
  }
})

// selectors

export const geCartSelector = state => {
  const items = state.cart.items
  const { discounts, subtotal, total } = state.summary
  return {
    items: items.map((o, i) => ({ ...o, cartItemId: i + 1 })),
    discounts,
    subtotal,
    total
  }
}

export const itemsSelector = state => state.cart.items
export const vouchersSelector = state => state.cart.vouchers

export const itemsCountSelector = state => {
  const { items } = state.cart
  return items.reduce((n, item) => n + Number(item.quantity), 0)
}

// @TODO remove?
export const totalPriceSelector = state => {
  const { items } = state.cart
  return items.reduce((n, item) => n + item.quantity * item.price, 0)
}

export const syncItems = createAsyncThunk(
  'cart/sync',
  async (args, { getState }) => {
    const items = args?.items || itemsSelector(getState())
    const ids = items.map(o => 'gid://shopify/ProductVariant/' + o.id)
    const variants = await getCartVariants({ ids }).catch(err => {
      return []
    })

    return items
      .map(item => {
        // match the item
        const id = btoa('gid://shopify/ProductVariant/' + item.id)
        const variant = variants.find(v => v.id == id)

        // if the item doesnt exist in the response, remove the item from the cart
        // because this item isn't available any more
        if (!variant) return null

        // update the cart with the new price, title, image
        return {
          ...item,
          sku: variant.sku,
          handle: variant.product.handle,
          title: variant.product.title,
          image:
            variant?.image?.originalSrc ||
            variant.product.images.edges[0].node.originalSrc,
          price: parseFloat(variant.priceV2.amount),
          variant: variant.title,
          options: variant.selectedOptions
        }
      })
      .filter(o => o)
  },
  {
    condition: (args, { getState }) => {
      if (args?.items.length) {
        return true
      }
      const isMagic = window.location.href.includes('/magic-link/')
      const items = itemsSelector(getState())

      return !isMagic && items.length > 0
    }
  }
)

export const {
  addItem,
  setQuantity,
  removeItem,
  addVoucher,
  removeVoucher,
  clearCart,
  fromMagicLink
} = cartSlice.actions

export default cartSlice.reducer
