import Link from '@/components/common/link'
import cx from 'clsx'
import styles from './button.module'

const Button = props => {
  const onClick = e => {
    props.onClick && !props.disabled && props.onClick(e)
  }

  const className = cx(styles.root, props.className, {
    [styles.thin]: props.thin,
    [styles.expand]: props.expand,
    [styles.light]: props.light,
    [styles.lightHover]: props.lightHover,
    [styles.grey]: props.grey,
    [styles.dark]: props.dark,
    [styles.outline]: props.outline,
    [styles.disabled]: props.disabled,
    [styles.text]: props.text
  })

  return (
    <Link
      href={props.href}
      className={className}
      onClick={onClick}
      fallback="button"
      disabled={props.disabled}
      {...(props.href ? {} : { type: props.type || 'button' })}
    >
      {props.children}
    </Link>
  )
}

export default Button
