import useGlobaleCountry from '@/hooks/intl/useGlobaleCountry'
import availableOptions from '@/lib/available-options'
import { colorToUrl } from '@/lib/query-strings'
import { getVariationStock } from '@/lib/stock'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useVariations = ({ options, variants, selected, setSelected }) => {
  const router = useRouter()
  const countryCode = useGlobaleCountry()
  const [sizes, setSizes] = useState(null)
  const [colors, setColors] = useState(null)

  // Get available colors
  const availableColors = availableOptions(variants, 'Color')

  // Get available sizes
  const availableSizes = availableOptions(variants, 'Size')

  // Adds the selected variant to the URL and sets it as selected
  const addSelected = (variantIndex, key, value) => {
    const searchParams = new URLSearchParams(window.location.search)

    if (value) {
      const urlValue = colorToUrl(value)
      searchParams.set(key, urlValue)
      const newUrl = window.location.pathname + '?' + searchParams.toString()
      router.replace(newUrl)
    }

    setSelected(variantIndex)
  }

  const calculateOptions = () => {
    const { selectedOptions } = variants[selected]
    const selectedSize = selectedOptions.find(o => o.name == 'Size')
    const selectedColor = selectedOptions.find(o => o.name == 'Color')

    if (availableSizes) {
      const _sizes = availableSizes
        .map((value, index) => {
          const variantIndex = variants.findIndex(o => {
            const size = o.selectedOptions.find(o => o.name == 'Size')
            const color = o.selectedOptions.find(o => o.name == 'Color')
            return (
              (!color || color.value == selectedColor.value) &&
              size.value == value
            )
          })

          const variant = variants[variantIndex] // selected variant
          const stock = getVariationStock(variant, countryCode) // get stock status

          // NOTE: for if we end up adding size to the URL parameter
          // const size = variant
          //   ? variant.selectedOptions.find(o => o.name == 'Size').value
          //   : '' // get size

          return {
            label: value,
            index,
            hideVariant: variant?.hideVariant,
            value: value,
            enabled: stock,
            available: variant ? true : false,
            selected: selectedSize.value == value,
            id: variant ? variant.id : 0,
            onClick: () => variant && setSelected(variantIndex)
          }
        })
        .filter(v => !v.hideVariant)
      setSizes(_sizes)
    }

    if (availableColors) {
      const _colors = availableColors
        .map((value, index) => {
          const variantIndex = variants.findIndex(o => {
            const size = o.selectedOptions.find(o => o.name == 'Size')
            const color = o.selectedOptions.find(o => o.name == 'Color')
            return (
              (!size || size.value == selectedSize.value) &&
              color.value == value
            )
          })

          const variant = variants[variantIndex] // selected variant
          const stock = getVariationStock(variant, countryCode) // get stock status
          const color = variant
            ? variant.selectedOptions.find(o => o.name == 'Color').value
            : '' // get color

          return {
            label: value,
            index,
            hideVariant: variant?.hideVariant,
            value: value,
            enabled: stock,
            available: variant ? true : false,
            selected: selectedColor.value == value,
            id: variant ? variant.id : 0,
            onClick: () => variant && addSelected(variantIndex, 'color', color)
          }
        })
        .filter(v => !v.hideVariant)
      setColors(_colors)
    }
  }
  useEffect(() => calculateOptions(), [options, variants, selected])

  return [sizes, colors]
}

export default useVariations
