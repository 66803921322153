import { fromCookie, fromEvent, fromWindow } from './slice/intl'
import { syncItems } from './slice/cart'
import { fetchCustomer } from './slice/customer'
import { setCookie } from '@/lib/cookie'
import { reloadWhenOurContentIsRemoved } from '@/lib/globale'

module.exports = store => {
  if (typeof window == 'undefined') return

  reloadWhenOurContentIsRemoved()

  if (window.location.href.includes('__embed/order-status')) return

  // dispatch init event
  store.dispatch({ type: 'INIT' })
  // sync cart prices. fetch new images etc.
  store.dispatch(syncItems())
  // @TODO this causes summary/update to fire twice
  store.dispatch(fetchCustomer())

  // listen to globale events
  window['GlobalE_Localize_Set'] = function (data) {
    store.dispatch(fromEvent(data))
  }

  setCookie('GlobalE_Welcome_Data', {
    showWelcome: false
  })

  // try to get GE data from the window
  if (
    window?.GEM_Services?.ConfigurationsService?.instance?.UserLocalizeSettings
  ) {
    store.dispatch(
      fromWindow(
        window.GEM_Services.ConfigurationsService.instance.UserLocalizeSettings
      )
    )
  }

  // try to get GE data from the cookie
  const cookie = document.cookie
    .split('; ')
    .find(o => o.includes('GlobalE_Data='))
  if (cookie) {
    try {
      const data = JSON.parse(decodeURIComponent(cookie.split('=').pop()))
      store.dispatch(fromCookie(data))
    } catch (err) {
      // @TODO use sentry?
      // console.error('GlobalE cookie cannot be json decoded')
    }
  }
}
