import gql from './client'
import { getNodesAndCursor } from './query-customer'

const query = gql`
  query ($accessToken: String!, $cursor: String!, $ordersPerPage: Int!) {
    customer(customerAccessToken: $accessToken) {
      orders(first: $ordersPerPage, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            name
            financialStatus
            fulfillmentStatus
            statusUrl
            processedAt
            orderNumber
            currentTotalPrice {
              amount
              currencyCode
            }
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            metafield(namespace: "tdr", key: "discounts") {
              namespace
              id
              key
              description
            }
            lineItems(first: 100) {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                node {
                  title
                  quantity
                  variant {
                    id
                    title
                    sku
                    selectedOptions {
                      name
                      value
                    }
                    image {
                      url
                    }
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const responseHandler = async res => {
  let { addresses: a } = res.customer

  let [addresses, addressCursor] = getNodesAndCursor(a)

  return {
    addresses,
    addressCursor
  }
}

export default query(responseHandler)
