export default [
  'AS',
  'AU',
  'CN',
  'CK',
  'FJ',
  'PF',
  'GU',
  'HK',
  'IN',
  'ID',
  'JP',
  'KI',
  'KR',
  'MO',
  'MY',
  'MH',
  'FM',
  'NR',
  'NC',
  'NZ',
  'NU',
  'MP',
  'PW',
  'PG',
  'PH',
  'WS',
  'SG',
  'SB',
  'LK',
  'TW',
  'TH',
  'TO',
  'TV',
  'VU'
]
