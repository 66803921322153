import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import basketRequest from '@/lib/api/basket'
import { itemsSelector } from './cart'

const initialState = {
  updating: false,
  submitted: false,
  subtotal: 0,
  total: 0,
  discounts: [],
  gifts: []
}

// slice
export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setSubmitted: (state, action) => {
      state.submitted = action.payload
    },
    'update/pending': state => {
      state.updating = true
    },
    'update/fulfilled': (state, action) => {
      state.updating = false
      state.subtotal = action.payload.subtotal
      state.total = action.payload.total
      state.discounts = action.payload.discounts
      state.gifts = action.payload.gifts
    },
    'update/rejected': state => {
      // @TODO handle this.
      // state.updating = true
    }
  }
})

export const submittedSelector = state => state.summary.submitted

export const cartSelector = state => ({
  items: Object.values(state.cart.items),
  vouchers: state.cart.vouchers,
  customerId: state.customer.id,
  email: state.customer.email,
  marketing: state.customer.acceptsMarketing
})

// async
export const update = createAsyncThunk(
  'summary/update',
  (args, { getState }) => {
    const cart = cartSelector(getState())

    if (cart.items.length == 0) {
      return {
        subtotal: 0,
        total: 0,
        discounts: [],
        gifts: []
      }
    }

    return basketRequest(cart)
  },
  {
    condition: (args, { getState }) => {
      const items = itemsSelector(getState())

      for (let item of items) {
        if (!item.sku) return false
      }

      return items.length
    }
  }
)

export const { setSubmitted } = summarySlice.actions

export default summarySlice.reducer
