const SizeGuideMens = () => {
  return (
    <>
      <h3>MENS SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Chest (in)</th>
              <th>Chest (cm)</th>
              <th>Waist (in)</th>
              <th>Waist (cm)</th>
              <th>Hip (in)</th>
              <th>Hip (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>XS</td>
              <td>36-38</td>
              <td>91-96</td>
              <td>28-30</td>
              <td>71-76</td>
              <td>32-34</td>
              <td>81-86</td>
            </tr>
            <tr>
              <td>S</td>
              <td>38-40</td>
              <td>96-101</td>
              <td>30-32</td>
              <td>76-81</td>
              <td>34-36</td>
              <td>86-91</td>
            </tr>
            <tr>
              <td>M</td>
              <td>40-42</td>
              <td>101-106</td>
              <td>32-34</td>
              <td>81-86</td>
              <td>36-38</td>
              <td>91-96</td>
            </tr>
            <tr>
              <td>L</td>
              <td>42-44</td>
              <td>106-111</td>
              <td>34-36</td>
              <td>86-91</td>
              <td>38-40</td>
              <td>96-101</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>44-46</td>
              <td>111-116</td>
              <td>36-38</td>
              <td>91-96</td>
              <td>40-42</td>
              <td>101-106</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>46-48</td>
              <td>116-121</td>
              <td>38-40</td>
              <td>96-101</td>
              <td>42-44</td>
              <td>106-111</td>
            </tr>
            <tr>
              <td>XXXL</td>
              <td>48-50</td>
              <td>121-126</td>
              <td>40-42</td>
              <td>101-106</td>
              <td>44-46</td>
              <td>111-116</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideMens.type = 'mens'

export default SizeGuideMens
