import { useState } from 'react'
import cx from 'clsx'
import Modal from '@/components/common/modal'
import Color from '@/components/common/color'
import useVariations from '@/hooks/product/useVariations'
import styles from './variations.module.scss'
import * as SizeGuides from '@/components/common/size-guides/'

const Variations = ({ sizeGuide, ...props }) => {
  const [modalOpen, setModal] = useState(false)
  const [sizes, colors] = useVariations(props)

  if (!sizes && !colors) {
    return null
  }

  const Guide = Object.values(SizeGuides).find(
    el => sizeGuide && sizeGuide === el.type
  )

  return (
    <>
      {Guide && (
        <Modal open={modalOpen} setOpen={setModal} logo large>
          <div className="mb-9">
            <Guide />
          </div>
        </Modal>
      )}
      <div className="mb-4">
        {sizes && (
          <div className={cx(styles.variations, styles.variationSizes)}>
            {Guide && (
              <div className="d-flex justify-content-end mb-2">
                <button
                  className="lh-normal btn-clear text-underline"
                  onClick={() => setModal(true)}
                  type="button"
                >
                  What&lsquo;s my size?
                </button>
              </div>
            )}
            <div className={styles.variationWrap}>
              <p className={styles.variationLabel}>Size</p>
              <ul>
                {sizes.map((size, i) => (
                  <li key={i}>
                    <label
                      className={cx({
                        [styles.disableSize]: !size.enabled,
                        [styles.notAvailable]: !size.available
                      })}
                    >
                      <input
                        type="radio"
                        name="product-size"
                        checked={size.selected}
                        onChange={size.onClick}
                      />
                      <span>{size.label}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {colors && (
          <div className={cx(styles.variations, styles.variationColors)}>
            <div className={styles.variationWrap}>
              <p className={styles.variationLabel}>Colour</p>
              <ul>
                {colors.map((color, i) => (
                  <li key={i}>
                    <Color
                      color={color.value}
                      onClick={color.onClick}
                      disabled={!color.enabled}
                      available={!color.available}
                      selected={color.selected}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Variations
