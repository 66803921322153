import styles from './banner.module.scss'
import Button from '@/components/common/button'
import Picture from '@/components/common/picture'

export default function Banner({ title, image }) {
  return (
    <div className={styles.root}>
      <div className="position-relative z-index-1 text-center">
        <h5 className={styles.title}>
          <span>{title}</span>
        </h5>
        <Button href="/account/register" lightHover thin>
          Join Now
        </Button>
      </div>
      {image && <Picture image={image} />}
    </div>
  )
}
