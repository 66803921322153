const SizeGuideInfant = () => {
  return (
    <>
      <h3>INFANT SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Age (years)</th>
              <th>Chest (cm)</th>
              <th>Waist (cm)</th>
              <th>Hip (cm)</th>
              <th>Height (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>[missing]</td>
              <td>52-53</td>
              <td>54-56</td>
              <td>54-56</td>
              <td>90-96</td>
            </tr>
            <tr>
              <td>[missing]</td>
              <td>53-56</td>
              <td>54-56</td>
              <td>56-69</td>
              <td>96-104</td>
            </tr>
            <tr>
              <td>[missing]</td>
              <td>56-59</td>
              <td>56-58</td>
              <td>59-61</td>
              <td>104-110</td>
            </tr>
            <tr>
              <td>[missing]</td>
              <td>59-61</td>
              <td>58-59</td>
              <td>61-63</td>
              <td>110-116</td>
            </tr>
            <tr>
              <td>[missing]</td>
              <td>61-63</td>
              <td>59-60</td>
              <td>63-66</td>
              <td>116-122</td>
            </tr>
            <tr>
              <td>[missing]</td>
              <td>63-66</td>
              <td>60-61</td>
              <td>66-70</td>
              <td>122-128</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

SizeGuideInfant.type = 'infant'

export default SizeGuideInfant
