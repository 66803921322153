import { useSelector } from 'react-redux'
import { statusSelector } from '@/lib/store/slice/storefront'
import useInterval from '../useInterval'
import { useState, useEffect } from 'react'

const showErrorsDuration = 5000

/**
 * Fetches the inProgress status, plus any recent errors.
 * If there are any recent errors, inProgress is set to true
 * This should disable submitting the same form again for
 * a few seconds.
 */
const useStatus = type => {
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const { inProgress, errors, fulfilled } = useSelector(statusSelector)

  const typeInProgress = inProgress[type]
  const typeFulfilled = fulfilled[type]

  const updateError = () => {
    const t = Date.now() - showErrorsDuration
    const e = errors.find(o => o.type == type && o.t > t)
    setError(e?.message)
    setSuccess(!!(typeFulfilled && typeFulfilled > t))
  }

  useEffect(updateError, [typeInProgress, typeFulfilled, errors.length])
  useInterval(updateError, error || success ? showErrorsDuration : null)

  return [typeInProgress || error, error, success]
}

export default useStatus
