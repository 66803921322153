import cx from 'clsx'
import Link from '@/components/common/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import useIsLoggedIn from '@/hooks/auth/useIsLoggedIn'
import styles from '../header.module.scss'

export const ButtonLogin = ({ setModal, label }) => {
  const loggedIn = useIsLoggedIn()

  if (loggedIn) {
    return (
      <Link
        href="/account"
        className={cx(
          styles.iconAccount,
          'color-white d-flex align-items-center lh-normal btn-clear'
        )}
      >
        <FontAwesomeIcon icon={faUser} size="lg" />
        {label && (
          <span className="text-uppercase text-bold font-heading ml-1 d-none d-md-inline-block">
            Account
          </span>
        )}
      </Link>
    )
  }

  return (
    <button
      onClick={() => setModal(true)}
      className="color-white d-flex align-items-center lh-normal btn-clear"
    >
      <FontAwesomeIcon icon={faUser} size="lg" />
      {label && (
        <span className="text-uppercase text-bold font-heading ml-1 d-none d-md-inline-block">
          Login
        </span>
      )}
    </button>
  )
}
