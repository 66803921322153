const SizeGuideWomens = () => {
  return (
    <>
      <h3>WOMENS SIZE GUIDE</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Chest (in)</th>
              <th>Chest (cm)</th>
              <th>Waist (in)</th>
              <th>Waist (cm)</th>
              <th>Hip (in)</th>
              <th>Hip (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>XXS (6)</td>
              <td>31</td>
              <td>79</td>
              <td>24</td>
              <td>61</td>
              <td>34</td>
              <td>86</td>
            </tr>
            <tr>
              <td>XS (8)</td>
              <td>33</td>
              <td>84</td>
              <td>26</td>
              <td>66</td>
              <td>36</td>
              <td>91</td>
            </tr>
            <tr>
              <td>S (10)</td>
              <td>35</td>
              <td>89</td>
              <td>28</td>
              <td>71</td>
              <td>38</td>
              <td>96</td>
            </tr>
            <tr>
              <td>M (12)</td>
              <td>37</td>
              <td>94</td>
              <td>30</td>
              <td>76</td>
              <td>40</td>
              <td>101</td>
            </tr>
            <tr>
              <td>L (14)</td>
              <td>39</td>
              <td>99</td>
              <td>32</td>
              <td>81</td>
              <td>42</td>
              <td>106</td>
            </tr>
            <tr>
              <td>XL (16)</td>
              <td>41</td>
              <td>104</td>
              <td>34</td>
              <td>86</td>
              <td>44</td>
              <td>111</td>
            </tr>
            <tr>
              <td>2XL (18)</td>
              <td>43</td>
              <td>109</td>
              <td>36</td>
              <td>91</td>
              <td>46</td>
              <td>116</td>
            </tr>
            <tr>
              <td>3XL (20)</td>
              <td>45</td>
              <td>114</td>
              <td>38</td>
              <td>96</td>
              <td>48</td>
              <td>121</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
SizeGuideWomens.type = 'womens'

export default SizeGuideWomens
