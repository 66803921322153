import cx from 'clsx'
import styles from './labels.module.scss'

const Labels = ({ labels = [], basket = false }) => {
  if (!labels.length) return null

  return (
    <ul
      className={cx(styles.root, {
        [styles.basket]: basket,
        [styles.stack]: labels.length > 2
      })}
    >
      {labels.map((label, index) => (
        <li key={index} className={cx(styles.li, {
          [styles.stack]: labels.length > 2
        })}>
          <div
            className={cx(styles.label, {
              [styles.new]: label == 'new',
              [styles.bundle]: label == 'bundle'
            })}
          >
            {label}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Labels
