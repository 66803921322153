import { useState, useEffect, useRef } from 'react'
import Icon from '@/components/common/icon'
import styles from './notifier.module'
import cx from 'clsx'

const Notifier = () => {
  const [events, _setEvents] = useState({})
  const eventRef = useRef(events)

  const setEvents = e => {
    eventRef.current = e
    _setEvents(e)
  }

  const handler = e => {
    let indexes = Object.keys(eventRef.current).map(Number)
    let index = 0
    while (indexes.includes(index)) index++

    setEvents({
      ...eventRef.current,
      [index]: e.detail
    })

    setTimeout(() => {
      if (!eventRef.current) return
      // eslint-disable-next-line
      const { [index]: _, ...newEvents } = eventRef.current
      setEvents(newEvents)
    }, 5000)
  }

  useEffect(() => {
    window.addEventListener('notify', handler)
    return () => window.removeEventListener('notify', handler)
  }, [])

  if (!Object.keys(events).length) {
    return null
  }

  return (
    <div className={cx(styles.root)}>
      {Object.entries(events).map(([index, event]) => (
        <div
          key={index}
          className={cx(styles.notice, {
            [styles.noticeShown]: event.ready
          })}
          style={{ top: `${index * 6}rem` }}
        >
          <Icon icon="Exclamation" />
          <span className="p-sm text-bold text-uppercase pl-2">
            {event.text}
          </span>
        </div>
      ))}
    </div>
  )
}
export default Notifier
