import { useSelector, useDispatch } from 'react-redux'
import {
  itemsSelector,
  vouchersSelector,
  setQuantity as _setQuantity,
  removeItem as _removeItem
} from '@/lib/store/slice/cart'

const useBasket = () => {
  const dispatch = useDispatch()
  const items = useSelector(itemsSelector)
  const vouchers = useSelector(vouchersSelector)

  const setQuantity = index => quantity =>
    dispatch(_setQuantity({ index, quantity }))
  const removeItem = index => () => dispatch(_removeItem({ index }))

  const count = items.reduce((a, v) => a + v.quantity, 0)

  return {
    items,
    vouchers,
    setQuantity,
    removeItem,
    count
  }
}

export default useBasket
