import gql from './client'
import { getNodesAndCursor } from './query-customer'

// example input
const variables = {
  id: 'gid://shopify/Product/1234'
}

// https://shopify.dev/api/storefront/reference/products/product
const query = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Product {
        id
        handle
        title
        description
        descriptionHtml
        shortDescription: metafield(
          namespace: "tdr"
          key: "short_description"
        ) {
          value
        }
        images(first: 100) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              altText
              originalSrc
              width
              height
            }
          }
        }
        variants(first: 100) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              availableForSale
              compareAtPriceV2 {
                amount
              }
              priceV2 {
                amount
              }
              quantityAvailable
              sku
              storeAvailability(first: 100) {
                edges {
                  node {
                    available
                    location {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async res => {
  let { variants: v, images: i, ...product } = res.node

  let [variants] = getNodesAndCursor(v)
  let [images] = getNodesAndCursor(i)

  return {
    ...product,
    variants,
    images
  }
}

export default query(responseHandler)
