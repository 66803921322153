import gql from './client'

// example input
const variables = {
  resetUrl: 'placeholder',
  password: 'HiZqFuDvDdQ7'
}

// https://shopify.dev/api/storefront/reference/customers/customeractivatebyurl
const query = gql`
  mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// handles the response that goes into redux. Rejects the response if needed
const responseHandler = async ({ customerActivateByUrl }) => {
  const { customerAccessToken, customerUserErrors } = customerActivateByUrl
  if (customerUserErrors.length) {
    throw new Error(customerUserErrors[0].message)
  }
  if (!customerAccessToken) {
    throw new Error('No Access Token')
  }

  return customerAccessToken
}

export default query(responseHandler)
