import * as SVGIcons from '@/svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faPinterest,
  faTiktok
} from '@fortawesome/free-brands-svg-icons' // brands
import {
  faRedo,
  faBoxOpen,
  faCreditCard,
  faTruck,
  faTimes,
  faTshirt,
  faCog,
  faAngleUp,
  faAngleDown,
  faEnvelope
} from '@fortawesome/pro-light-svg-icons' // light
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faMinus,
  faTag,
  faSearch,
  faCheck,
  faExternalLink
} from '@fortawesome/pro-regular-svg-icons' // regular
import {
  faTrash,
  faPencil,
  faSpinner,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faHome,
  faTrashAlt
} from '@fortawesome/pro-solid-svg-icons' // solid

const faIcons = {
  Facebook: faFacebookF,
  Twitter: faTwitter,
  Youtube: faYoutube,
  Instagram: faInstagram,
  Pinterest: faPinterest,
  TikTok: faTiktok,
  Refresh: faRedo,
  Box: faBoxOpen,
  Card: faCreditCard,
  Truck: faTruck,
  Shirt: faTshirt,
  Cog: faCog,
  AngleUp: faAngleUp,
  AngleDown: faAngleDown,
  ArrowLeft: faArrowLeft,
  ArrowRight: faArrowRight,
  Plus: faPlus,
  Minus: faMinus,
  Times: faTimes,
  Tag: faTag,
  Search: faSearch,
  Check: faCheck,
  Trash: faTrash,
  TrashAlt: faTrashAlt,
  Pencil: faPencil,
  Spinner: faSpinner,
  TimesCircle: faTimesCircle,
  ChevronUp: faChevronUp,
  ChevronDown: faChevronDown,
  ChevronLeft: faChevronLeft,
  ChevronRight: faChevronRight,
  Exclamation: faExclamationCircle,
  Home: faHome,
  ExternalLink: faExternalLink,
  Envelope: faEnvelope
}

const Icon = ({ icon, size, spin = false, pulse = false }) => {
  let CustomSvg = ''

  if (SVGIcons[icon]) {
    CustomSvg = SVGIcons[icon]
    return <CustomSvg />
  } else if (faIcons[icon]) {
    return (
      <FontAwesomeIcon
        icon={faIcons[icon]}
        size={size}
        spin={spin}
        pulse={pulse}
      />
    )
  }

  return false
}

export default Icon
