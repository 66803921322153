import Icon from '@/components/common/icon'
import styles from './scroll-to-top.module'
import useScroll from '@/hooks/useScroll'
import cx from 'clsx'

const ScrollToTop = () => {
  const scroll = useScroll()

  const doScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div
      onClick={doScroll}
      className={cx(styles.root, { [styles.visible]: scroll > 100 })}
    >
      <Icon icon="ChevronUp" size="lg" />
    </div>
  )
}
export default ScrollToTop
